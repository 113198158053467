import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRegistrationFormComponent } from './components/auth-registration-form/auth-registration-form.component';

import { PwResetFormComponent } from './components/pw-reset-form/pw-reset-form.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
    {
        path: 'registration',
        component: AuthRegistrationFormComponent,
    },
    {
        path: 'resetPassword',
        component: ResetPasswordComponent,
    },
    {
        path: 'pwReset',
        component: PwResetFormComponent,
    },
    {
        path: 'emailVerification',
        component: EmailVerificationComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthLibRoutingModule {}
