import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantService } from '../tenant.service';
import { TenantFieldComponent } from './components/tenant-field/tenant-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HeaderComponent } from './components/header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatAutocompleteModule,
        TenantFieldComponent, HeaderComponent,
    ],
    providers: [{ provide: TenantService, useClass: TenantService }],
    exports: [TenantFieldComponent, HeaderComponent],
})
export class SharedModule {}
