import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class VisualisationService {
    private darkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private showPortfolio: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(@Inject(CookieService) private cookieService: CookieService) {}

    get $darkTheme(): Observable<boolean> {
        return this.darkTheme.asObservable();
    }

    changePortfolio(bool: boolean) {
        this.showPortfolio.next(bool);
    }
    receivePortfolio(): Observable<boolean> {
        return this.showPortfolio.asObservable();
    }
    toggleTheme() {
        const value = !this.darkTheme.value;
        this.darkTheme.next(value);
        this.cookieService.set('theme', String(this.darkTheme.value), 365, '/');
    }

    setDarkTheme(value: boolean) {
        this.darkTheme.next(value);
        this.cookieService.set('theme', String(this.darkTheme.value), 365, '/');
    }
}
