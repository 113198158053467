<div class="purchase-modal" [ngClass]="{ show: isOpen }">
  <div class="purchase-modal_body" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="purchase-modal_body-header" [ngClass]="{ light: (1 | colourTheme) === false }">
      <span>{{ titleText | translate }}</span>
      <img src="assets/img/clear.svg" (click)="close()" />
    </div>

    @if (bodyText) {
      <div class="purchase-modal_body-text" [ngClass]="{ light: (1 | colourTheme) === false }">
        {{ bodyText | translate }}
      </div>
    }
    <div class="purchase-modal_body-control">
      <button class="purchase-modal_body-control_button confirm" (click)="performPositive()">
        {{ positiveButtonText | translate }}
      </button>
      <button
        class="purchase-modal_body-control_button cancel"
        (click)="close()"
        [ngClass]="{ light: (1 | colourTheme) === false }"
        >
        {{ 'cabinet.purchase.secondTab.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
