import { Injectable } from '@angular/core';
import { ApiService } from '../../../app/modules/ak/services/api.service';

@Injectable({ providedIn: 'root' })
export class PersonService extends ApiService {
    getRoute(): string {
        return 'persons';
    }

    getPerson(personId: string) {
        let url = this.getUrl() + `/${personId}`;
        return this.http.get<any>(url);
    }

    getAll(page: any, sort: any, filter: any) {
        let paramsInfo: any = {};
        paramsInfo.pageNumber = `page=${page?.pageIndex !== undefined ? page.pageIndex : 0}`;
        paramsInfo.pageSize = `size=${page?.pageSize !== undefined ? page.pageSize : 10}`;
        paramsInfo.sortColumn = `sort=${sort?.sortColumn !== undefined ? sort.sortColumn : 'id'}`;
        paramsInfo.sortDirection = `sortDirection=${sort?.sortDirection !== undefined ? sort.sortDirection : 'asc'}`;

        if (filter?.acccountNumber !== undefined && filter?.acccountNumber !== null && filter.acccountNumber !== '') {
            paramsInfo.acccountNumber = `penum2=${filter.acccountNumber}`;
        }

        if (filter?.personNumber !== undefined && filter?.personNumber !== null && filter.personNumber !== '') {
            paramsInfo.personNumber = `penum=${filter.personNumber}`;
        }

        if (filter?.dateOfBirth !== undefined && filter?.dateOfBirth !== null && filter.dateOfBirth !== '') {
            paramsInfo.dateOfBirth = `dateOfBirth=${filter.dateOfBirth}`;
        }

        if (filter?.firstName !== undefined && filter?.firstName !== null && filter.firstName !== '') {
            paramsInfo.firstName = `firstName=${filter.firstName}`;
        }

        if (filter?.lastName !== undefined && filter?.lastName !== null && filter.lastName !== '') {
            paramsInfo.lastName = `lastName=${filter.lastName}`;
        }

        if (filter?.foundationId !== undefined && filter?.foundationId !== null && filter.foundationId !== '') {
            paramsInfo.foundationId = `foundationId=${filter.foundationId}`;
        }

        if (filter?.companyId !== undefined && filter?.companyId !== null && filter.companyId !== '') {
            paramsInfo.companyId = `companyId=${filter.companyId}`;
        }

        if (filter?.pensionFundId !== undefined && filter?.pensionFundId !== null && filter.pensionFundId !== '') {
            paramsInfo.pensionFundId = `pensionFundId=${filter.pensionFundId}`;
        }

        let url = this.getUrl() + '/pageable?';
        // Iterate through the properties in paramsInfo and add them to the URL
        for (const key of Object.keys(paramsInfo)) {
            url += paramsInfo[key] + '&';
        }

        // Remove the trailing '&' if it exists
        if (url.endsWith('&')) {
            url = url.slice(0, -1);
        }

        return this.http.get<any>(url);
    }

    // call api gateway for updating personal info
    updatePersonalInfo(personalInformation: any, personId: number) {
        let url = `${this.getUrl()}/${personId}/personalInformation`;
        return this.http.put<any>(url, personalInformation);
    }
}
