import { Component, Input, OnInit } from '@angular/core';
import { IVoteCard } from '../../../../app/modules/home/services/my-swarm-feed.service';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { VoteMeterComponent } from '../vote-meter/vote-meter.component';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'app-vote-card',
    templateUrl: './vote-card.component.html',
    styleUrls: ['./vote-card.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, VoteMeterComponent, TranslateModule, ColourThemePipe],
})
export class VoteCardComponent implements OnInit {
    @Input() index: number;
    @Input() voteCard: IVoteCard;

    voteChoices: Array<Choice> = [
        {
            selected: false,
            value: 'yes',
            image: '/assets/img/vote/Yes.svg',
            imageActive: '/assets/img/vote/Yes-active.svg',
            percent: 60,
        },
        {
            selected: false,
            value: 'neutral',
            image: '/assets/img/vote/Neutral.svg',
            imageActive: '/assets/img/vote/Neutral-active.svg',
            percent: 25,
        },
        {
            selected: false,
            value: 'no',
            image: '/assets/img/vote/No.svg',
            imageActive: '/assets/img/vote/No-active.svg',
            percent: 15,
        },
    ];

    constructor() {}

    getChoiceImage(choice: Choice): string {
        return choice.selected ? choice.imageActive : choice.image;
    }

    choiceClick(choice: Choice): void {
        if (choice.selected) {
            choice.selected = false;
            return;
        }
        this.voteChoices.map((item) => (item.selected = false));
        choice.selected = !choice.selected;
    }

    ngOnInit(): void {}
}

export interface Choice {
    selected: boolean;
    value: 'yes' | 'no' | 'neutral';
    image: string;
    imageActive: string;
    percent?: number;
}
