import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployerGuard } from '../../../app/core/guards/employer.guard';
import { CMMainComponent } from './components/cm-main/cm-main.component';
import { ContentWizardComponent } from './components/content-wizard/content-wizard.component';

const routes: Routes = [
    {
        path: '',
        component: CMMainComponent,
    },
    {
        path: 'content-wizard/:id',
        component: ContentWizardComponent,
        canActivate: [EmployerGuard],
    },
    {
        path: 'content-wizard',
        component: ContentWizardComponent,
        canActivate: [EmployerGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CMRoutingModule {}
