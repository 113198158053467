import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { EMPTY, Subject, switchMap, takeUntil, zip } from 'rxjs';
import { UserService } from '../../../../../app/core/services/user.service';
import { SnackbarService } from '../../../../../app/modules/ak/services/snackbar.service';
import { CMService } from '../../services/cm.service';
import {
    MatTableDataSource,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
} from '@angular/material/table';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocaleService } from '../../../../../app/core/services/locale.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../app/modules/ak/pages/account-movements/components/confirmation-dialog/confirmation-dialog.component';
import { DateAdapter, MatOption } from '@angular/material/core';
import {
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { UpperCasePipe, DatePipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-cm-main',
    templateUrl: './cm-main.component.html',
    styleUrls: ['./cm-main.component.scss'],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatSelect,
        MatOption,
        MatDateRangeInput,
        ReactiveFormsModule,
        MatStartDate,
        MatEndDate,
        MatDatepickerToggle,
        MatDateRangePicker,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        UpperCasePipe,
        DatePipe,
        TranslateModule,
    ],
})
export class CMMainComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private cmService: CMService,
        private snackBarService: SnackbarService,
        private translateService: TranslateService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private localeService: LocaleService,
        private dialog: MatDialog,
        private dateAdapter: DateAdapter<Date>
    ) {}
    contentList: any[];
    hasUserEmployerRole;
    dataSource = new MatTableDataSource<any>([]); // Initialize with your data array
    displayedColumns: string[] = [
        'id',
        'contentType',
        'renderEngine',
        'title',
        'createDate',
        'editDate',
        'status',
        'actions',
    ];
    renderEngines = [
        { key: 'editor.js', value: 'EDITOR' },
        { key: 'survey.js', value: 'SURVEY' },
    ];
    dateRange: FormGroup;
    title: string = '';
    locale: string;
    destroyed$: Subject<boolean> = new Subject<boolean>();
    filterDebounceTimer: any = null;
    debounceDelay: number = 300;
    totalElements: number;

    filters = {
        title: null,
        renderEngine: this.renderEngines[0].value,
        startDate: null,
        endDate: null,
        page: 0,
        size: 10,
        sortColumn: 'createdDate',
        sortDirection: 'DESC',
    };

    startDateTranslation: string;
    endDateTranslation: string;

    ngOnInit(): void {
        this.dateRange = this.formBuilder.group({
            start: [''],
            end: [''],
        });
        this.userService.isEmployer$.subscribe((isEmployer) => (this.hasUserEmployerRole = isEmployer));
        this.getContent();
        this.localeService.$currentLocale.pipe(takeUntil(this.destroyed$)).subscribe((locale: string) => {
            this.locale = locale;
            this.dateAdapter.setLocale(locale);
            this.startDateTranslation = this.translateService.instant('cm.filters.start-date');
            this.endDateTranslation = this.translateService.instant('cm.filters.end-date');
        });
    }

    getContent() {
        this.cmService.getContent(this.filters).subscribe({
            next: (contentList) => {
                this.contentList = contentList.content;
                this.dataSource.data = this.contentList;
                this.totalElements = contentList.totalElements;
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        clearTimeout(this.filterDebounceTimer);
    }

    editContent(content) {
        this.router.navigate(['/content-management/content-wizard', content.id]);
    }

    deleteContent(content) {
        let title = this.translateService.instant('cabinet.news.deletionTitle');
        let message = this.translateService.instant('cabinet.news.deletionMessage', {
            title: content.title[this.locale] ?? content.title?.de,
        });
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { title, message },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((deletionConfirmed) => {
                    if (deletionConfirmed) {
                        return this.cmService.deleteContent(content.id);
                    } else {
                        return EMPTY;
                    }
                })
            )
            .subscribe({
                next: () => {
                    const index = this.contentList.findIndex((card) => card.id === content.id);
                    const x = this.contentList.splice(index, 1);
                    this.snackBarService.showSuccessAlert(
                        this.translateService.instant('cabinet.news.wizard.messages.articleSuccessfullyDeleted')
                    );
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    openContent(content) {
        this.router.navigate(['/content-management/content', content.id], {
            state: {
                response: content,
            },
        });
    }

    getDate(date) {
        return moment(date).format('DD.MM.YYYY');
    }

    openCreateNewContent() {
        this.router.navigate(['/content-management/content-wizard']);
    }

    applyFilter() {
        clearTimeout(this.filterDebounceTimer);
        this.filterDebounceTimer = setTimeout(() => {
            this.executeFilterLogic();
        }, this.debounceDelay);
    }

    executeFilterLogic() {
        this.filters.startDate = this.dateRange.get('start')?.value
            ? moment(this.dateRange.get('start')?.value).format('YYYY-MM-DD')
            : null;
        this.filters.endDate = this.dateRange.get('end')?.value
            ? moment(this.dateRange.get('end')?.value).format('YYYY-MM-DD')
            : null;

        if (!this.filters.endDate && this.filters.startDate) {
            console.log('Please select an end date.');
            return;
        }

        this.filters = {
            ...this.filters,
            page: 0,
            size: 10,

            sortColumn: 'createdDate',
            sortDirection: 'DESC',
        };

        this.getContent();
    }

    clearDateRange(value: string) {
        switch (value) {
            case 'title':
                this.filters.title = null;
                break;
            case 'renderEngine':
                this.filters.renderEngine = null;
                break;
            case 'dateRange':
                this.dateRange.patchValue({
                    start: '',
                    end: '',
                });
                break;
            default:
                break;
        }

        this.applyFilter();
    }

    pageEvent(event: PageEvent) {
        this.filters.size = event.pageSize;
        this.filters.page = event.pageIndex;
        this.getContent();
    }
}
