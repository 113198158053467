<div class="input-date-box">
    <mat-label [ngClass]="{ light: (1 | colourTheme) === false }">{{
        'cabinet.purchase.firstTab.dropdownFirst.fieldName' | translate
    }}</mat-label>
    <mat-form-field class="datepicker" [ngClass]="{ light: (1 | colourTheme) === false }" appearance="outline">
        <input
            matInput
            [matDatepicker]="picker"
            [formControl]="formControl"
            [min]="minDate"
            [max]="maxDate"
            [matDatepickerFilter]="dateFilter"
            [value]="currentValue"
            readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
