import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    standalone: true,
    imports: [NgClass, ColourThemePipe],
})
export class PreloaderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
