import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [],
})
export class HeaderComponent {
    @Input() logoUrl: string;
    @Input() envLogo: string;
    envLogoPrefix = '../../../../../assets/img/logos/';

    showClanLogo = false;
    constructor() {
        this.showClanLogo =
            window.location.hostname === /*'localhost'*/ 'employer.pv-clan.ch';
    }
}
