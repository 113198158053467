import { RoleType } from 'libs/shared/src/lib/models/enums/role-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthObserver } from './auth-observer';
import { LoginEvent, LogoutEvent } from './model/AuthEvent';
import { ActivatedRoute } from '@angular/router';
import { RegisterRequest } from './model/RegisterRequest';
import { environment } from 'apps/swarm/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    cookieTokenName = 'swarm-auth-token';
    cookieRefreshTokenName = 'swarm-refresh-token';
    tenantCookieName = 'tenant';
    redirectToCookieName = 'redirectTo';
    transactionIdCookieName = 'transactionId';
    authUrl = environment.authApiUrl;

    constructor(private http: HttpClient, private observer: AuthObserver, private activatedRoute: ActivatedRoute) {}

    get authToken() {
        return sessionStorage.getItem(this.cookieTokenName);
    }

    get authRefreshToken() {
        return sessionStorage.getItem(this.cookieRefreshTokenName);
    }
    get tenant() {
        return sessionStorage.getItem(this.tenantCookieName);
    }

    private setInStorage(
        data: { token: string; refreshToken: string },
        tenant: string,
        redirectTo?: string,
        transactionId?: string
    ): void {
        sessionStorage.setItem(this.cookieTokenName, data.token);
        sessionStorage.setItem(this.cookieRefreshTokenName, data.refreshToken);
        sessionStorage.setItem(this.tenantCookieName, tenant);
        if (redirectTo && transactionId) {
            localStorage.setItem(this.redirectToCookieName, redirectTo);
            localStorage.setItem(this.transactionIdCookieName, transactionId);
        }
    }

    removeFromStorage() {
        sessionStorage.clear();
    }

    checkIfResetPasswordTokenValid<T>(token: { token: string }): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/checkIfResetPasswordTokenValid`, token, { headers });
    }

    checkIfEmailExists<T>(email: { email: string }): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/checkIfEmailExists`, email, { headers });
    }

    resetPasswordbyHand<T>(pwData: { password: string; token: string }, tenant: string): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/resetPasswordbyHand?tenant=${tenant}`, pwData, { headers });
    }

    setNewEmail<T>(emailData: {
        email: string;
        oldemail: string;
        header: string;
        salutation: string;
        body: string;
        body2: string;
        supportText: string;
        securityText: string;
        bodyAfterLink: string;
        regards: string;
        lang: string;
        url: string;
    }): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/setNewEmail`, emailData, { headers });
    }

    activateEmail<T>(token: { token: string }, tenant: string): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/activateEmail?tenant=${tenant}`, token, { headers });
    }

    createUser<T>(registerData: RegisterRequest, tenant: string): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/createuser?tenant=${tenant}`, registerData, { headers });
    }

    loginFirstStep<T>(loginData: { login: string; password: string }, tenant: string): Observable<T> {
        return this.http.post<T>(`${this.authUrl}/auth/step1?tenant=${tenant}`, {
            login: loginData.login,
            password: loginData.password,
            portal: RoleType.EMPLOYEE,
        });
    }

    loginSecondStep<T>(otpData: { authId: string; otp: string }): Observable<T> {
        return this.http.post<T>(`${this.authUrl}/auth/step2`, otpData);
    }

    resetPassword<T>(
        emailData: {
            email: string;
            header: string;
            salutation: string;
            body: string;
            body2: string;
            supportText: string;
            securityText: string;
            bodyAfterLink: string;
            regards: string;
            lang: string;
            url: string;
        },
        tenant: string
    ): Observable<T> {
        const headers = { 'Content-Type': 'application/json' };
        return this.http.post<T>(`${this.authUrl}/auth/sendResetPasswordEmail?tenant=${tenant}`, emailData, {
            headers,
        });
    }

    logout<T>(): Observable<T> {
        const tenant = sessionStorage.getItem('tenant');
        const url = `${this.authUrl}/auth/logout?tenant=${tenant}`;
        const refreshToken = this.authRefreshToken;
        this.removeFromStorage();
        this.observer.addNewEvent(new LogoutEvent());
        return this.http.post<T>(url, { refreshToken });
    }

    logIn({ token, refreshToken }: { token: string; refreshToken: string }, tenant: string): void {
        const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
        const transactionId = this.activatedRoute.snapshot.queryParamMap.get('transactionId');
        redirectTo && transactionId
            ? this.setInStorage({ refreshToken, token }, tenant, redirectTo, transactionId)
            : this.setInStorage({ refreshToken, token }, tenant);
        this.observer.addNewEvent(new LoginEvent());
    }

    public isValid(): boolean {
        return (
            sessionStorage.getItem(this.cookieTokenName) !== null &&
            sessionStorage.getItem(this.cookieRefreshTokenName) !== null
        );
    }

    refreshTokenReq() {
        const tenant = this.tenant;
        const refreshToken = this.authRefreshToken;
        return this.http.post(`${this.authUrl}/auth/refresh?tenant=${tenant}`, {
            refreshToken,
        });
    }
}
