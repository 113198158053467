import {Observable, ReplaySubject} from "rxjs";
import {AuthEvent} from "./model/AuthEvent";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class AuthObserver {
    private authEventReplaySubject: ReplaySubject<AuthEvent> = new ReplaySubject<AuthEvent>(1)

    public get observer() : Observable<AuthEvent> {
        return this.authEventReplaySubject.asObservable()
    }

    addNewEvent(event: AuthEvent) : void {
        this.authEventReplaySubject.next(event)
    }
}
