import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatFormField,
        MatLabel,
        MatInput,
        MatDatepickerInput,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        ColourThemePipe,
    ],
})
export class DatepickerComponent implements OnInit {
    formControl: FormControl = new FormControl(new Date());
    @Output() valueChanged: EventEmitter<Date> = new EventEmitter();
    @Input() type: 'input' | 'icon' = 'icon';

    constructor() {}

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe((value) => {
            return this.valueChanged.emit(value);
        });
    }
}
