import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Directory } from './model/Directory';

import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'apps/swarm/src/environments/environment';
import { Locale } from 'libs/shared/src/lib/models/types/locale';

@Injectable({
    providedIn: 'root',
})
export class DirectoryService {
    private sortedDirectories: ReplaySubject<Directory[]> = new ReplaySubject<Directory[]>(1);

    private all: Directory[] = [];
    authUrl = environment.authApiUrl;
    constructor(private http: HttpClient) {}

    get allDirectories(): Observable<Directory[]> {
        return this.sortedDirectories.asObservable();
    }

    updateAllDirectories(currentLocale: Locale): void {
        this.getAllDirectoriesFromDBConfig(currentLocale).subscribe((directories) => {
            this.all = directories;
            this.sortedDirectories.next(this.sortDirectories(currentLocale, directories));
        });
    }

    changeLanguage(currentLocale: Locale): void {
        this.sortedDirectories.next(this.sortDirectories(currentLocale, this.all));
    }

    private sortDirectories(sortByLocale: Locale, directories: Directory[]) {
        const searchColumn = 'name_' + sortByLocale;
        directories.sort((a, b) => {
            const aProp = a[searchColumn];
            const bProp = b[searchColumn];
            if (aProp === undefined || aProp === null || bProp === undefined || bProp === null) {
                return 0; // return 0 if any property is undefined or null
            }
            return aProp > bProp ? 1 : -1;
        });
        return directories;
    }

    private getAllDirectoriesFromDBConfig(currentLocale: Locale) {
        return this.http.get<Directory[]>(`${this.authUrl}/directory/all?lang=${currentLocale}`);
    }

    getMatchingTenant(searchValue: string, currentLocale: string) {
        const currentLocaleName = `name_${currentLocale}`;
        return this.http.get<Directory[]>(
            `${this.authUrl}/directory/matchingTenants?searchBy=${currentLocaleName},tenantid&enteredValue=${searchValue}`
        );
    }
}
