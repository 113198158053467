import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { GlobalEventsService } from '../../../app/services/global/global-events-service';

@Injectable({
    providedIn: 'root',
})
export class AkGuard implements CanActivate {
    constructor(private globalEventsService: GlobalEventsService) {}

    canActivate(): boolean {
        const hasAk = sessionStorage.getItem('hasAk');
        return hasAk === 'true' ? true : false;
    }
}
