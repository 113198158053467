import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication.service';
import { TenantService } from '../../tenant.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { Directory } from '../../model/Directory';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { TenantFieldComponent } from '../../shared/components/tenant-field/tenant-field.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lib-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TenantFieldComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    RouterLink,
    TranslateModule
],
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild('tenantElement') tenantElement: ElementRef;
    showClanLogo = false;
    //resetPasswordForm: FormGroup;
    email: string;
    resetBtnPressed = false;
    currentLanguage: any;
    inputFieldResetPassword = {
        email: {
            active: false,
            type: 'text',
        },
        password: {
            active: false,
            type: 'password',
        },
        tenant: {
            active: false,
            type: 'text',
        },
    };
    emailHeaderText: string;
    emailSalutation: string;
    emailBodyText: string;
    emailBodyText2: string;
    emailBodyAfterLinkText: string;
    emailSupportText: string;
    emailSecurityText: string;
    emailRegards: string;

    isUserPersisten = true;
    url: string;
    selectedValue: string;
    showTenantList = false;
    noTenantSelected = false;
    selectedTenant: string | undefined;
    currentLocale: Locale;
    allLanguages: Locale[];
    directoriesList: Directory[] = [];
    tenantPasswordResetControl: FormControl = new FormControl();

    @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

    resetPasswordForm: FormGroup;
    private tenantParam: string;
    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private localeService: LocaleService,
        private tenantService: TenantService,
        private cookieService: CookieService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.tenantParam = this.route.snapshot.queryParams['tenant'];
        if (!this.tenantParam) {
            this.router.navigate(['/auth']);
        }
        this.allLanguages = this.localeService.localeList;
        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLocale = locale;
        });
        this.resetPasswordForm = this.formBuilder.group({
            tenant: [''],
            email: ['', [Validators.required, Validators.email]],
        });

        this.currentLocale = this.cookieService.get('lang') as Locale;
        if (this.currentLocale) {
            this.selectLanguage(this.currentLocale);
            this.cookieService.set('lang', this.currentLocale);
        } else {
            this.localeService.$currentLocale.subscribe((locale) => {
                this.currentLocale = locale;
                this.selectLanguage(this.currentLocale);
                return this.currentLocale;
            });
        }
    }
    get emailControl() {
        return this.resetPasswordForm.get('email');
    }

    getCurrentLocale(locale: Locale) {
        this.currentLocale = locale;
    }
    getDirectoryList(directories: Directory[]) {
        this.directoriesList = directories;
    }

    receiveSelectedTenant(selectedTenantData: Directory) {
        this.selectedTenant = selectedTenantData?.tenantId;
    }

    async resetPassword() {
        this.email = this.resetPasswordForm.controls['email'].value.toString();
        this.currentLanguage = this.currentLocale;
        this.noTenantSelected = false;
        if (!this.selectedTenant) {
            this.noTenantSelected = true;
            return;
        }
        this.url = window.location.host;
        this.authenticationService
            .checkIfEmailExists<checkIfEmailExists>({
                email: this.email,
            })
            .pipe(map((res) => res))
            .subscribe({
                next: (res) => {
                    if (res.toString() == '0') {
                        this.isUserPersisten = true;
                        this.resetBtnPressed = true;
                    } else {
                        this.isUserPersisten = false;
                        this.resetBtnPressed = false;
                    }
                },
                error: (err) => {
                    console.log(err.status);
                },
            });

        if (this.isUserPersisten) {
            const translations = this.translateService.instant('auth.resetEmail');

            this.emailHeaderText = translations.header;
            this.emailSalutation = translations.salutation;
            this.emailBodyText = translations.body;
            this.emailBodyText2 = translations.body2;
            this.emailSecurityText = translations.security;
            this.emailSupportText = translations.support;
            this.emailBodyAfterLinkText = translations.bodyAfterLink;
            this.emailRegards = translations.regards;

            this.authenticationService
                .resetPassword<Email>(
                    {
                        email: this.email,
                        header: this.emailHeaderText,
                        salutation: this.emailSalutation,
                        body: this.emailBodyText,
                        body2: this.emailBodyText2,
                        bodyAfterLink: this.emailBodyAfterLinkText,
                        supportText: this.emailSupportText,
                        securityText: this.emailSecurityText,
                        regards: this.emailRegards,
                        lang: this.currentLanguage,
                        url: this.url,
                    },
                    this.selectedTenant
                )
                .pipe(map((res) => res))
                .subscribe({
                    next: () => {
                        /*this.logger.info(res);*/
                    },
                    error: (error) => {
                        console.log(error.status);
                    },
                });
            this.resetBtnPressed = true;
        }
    }

    get f() {
        return this.resetPasswordForm.controls;
    }

    selectLanguage(locale: Locale) {
        this.localeService.changeLocale(locale);
        this.tenantService.selectLanguage(locale);
        this.tenantService.languageChanged(locale);
    }

    inputFocusHandler(fieldName: 'email' | 'password'): void {
        const existInputField = this.inputFieldResetPassword[fieldName];
        if (existInputField) {
            existInputField.active = true;
        }
    }

    inputBlurHandler(fieldName: 'email' | 'password' | 'tenant'): void {
        const existInputField = this.inputFieldResetPassword[fieldName];
        if (existInputField && !this.f[fieldName].value.trim()) {
            existInputField.active = false;
        }
    }
}

type checkIfEmailExists = {
    email: string;
};

type Email = {
    email: string;
    header: string;
    salutation: string;
    body: string;
    body2: string;
    bodyAfterLink: string;
    supportText: string;
    securityText: string;
    regards: string;
    lang: string;
    url: string;
};
