import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthObserver } from './auth-observer';
import { LoginEvent } from './model/AuthEvent';
import { TenantService } from './tenant.service';
import { KeycloakService } from 'keycloak-angular';
import { DirectoryService } from './directory.service';

@Injectable({
    providedIn: 'root',
})
export class SSOService {
    cookieTokenName = 'swarm-auth-token';
    cookieRefreshTokenName = 'swarm-refresh-token';
    adClientId: string;
    adRedirectUri: string;
    adResponseType: string;
    adScope: string;
    keycloakUrl: string;
    tenantId: string;
    tenantValues;
    constructor(
        private http: HttpClient,
        private observer: AuthObserver,
        private tenantService: TenantService,
        private keycloak: KeycloakService
    ) {}
    getTenantData(tenantData) {
        this.tenantValues = {
            keycloakUrl: tenantData.authorizeUrl,
            adClientId: tenantData.ad_clientid,
            adRedirectUri: tenantData.ad_redirecturi,
            adResponseType: tenantData.ad_responsetype,
            adScope: tenantData.ad_scope,
            tenantId: tenantData.tenantId,
        };

        return this.tenantValues;
    }
    initializeKeycloak(tenantValues): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await this.keycloak.init({
                    config: {
                        url: tenantValues?.keycloakUrl,
                        realm: tenantValues?.tenantId,
                        clientId: tenantValues?.adClientId,
                    },
                    loadUserProfileAtStartUp: false,
                    initOptions: {
                        onLoad: 'login-required',
                        redirectUri: tenantValues?.adRedirectUri,
                        checkLoginIframe: true,
                    },
                    bearerExcludedUrls: [],
                });

                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    static parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
}
