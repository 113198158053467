<div class="switcher" (click)="toggleSwicther()" [@switchColor]="switcherState ? 'off' : 'on'">
  @if (!simpleIcon) {
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="switcher__btn"
        [ngClass]="{
            'switcher__btn--off': switcherState == false,
            'switcher__btn--on': switcherState == true,
            opposite: opposite
        }"
      >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12ZM12.6667 13.3333V6.66667H11.3333V13.3333H12.6667ZM15.04 8.29333L14.0733 9.26C15.2267 9.96 16 11.22 16 12.6667C16 14.8733 14.2067 16.6667 12 16.6667C9.79333 16.6667 8 14.8733 8 12.6667C8 11.22 8.77333 9.96 9.92 9.25333L8.96 8.29333C7.57333 9.25333 6.66667 10.8533 6.66667 12.6667C6.66667 15.6133 9.05333 18 12 18C14.9467 18 17.3333 15.6133 17.3333 12.6667C17.3333 10.8533 16.4267 9.25333 15.04 8.29333Z"
        fill="#F2F2F2"
        />
      </svg>
    }
    @if (simpleIcon) {
      <div
        class="switcher-circle"
        [ngClass]="{
            'switcher__btn--off': switcherState == false,
            'switcher__btn--on': switcherState == true,
            'switcher-circle__btn--off': switcherState == false,
            'switcher-circle__btn--on': switcherState == true,
            opposite: opposite
        }"
      ></div>
    }
  </div>
