import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lib-pw-reset-form',
    templateUrl: './pw-reset-form.component.html',
    styleUrls: ['./pw-reset-form.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
],
})
export class PwResetFormComponent implements OnInit {
    pwResetForm: FormGroup;
    loginErrors = false;
    submitted = false;
    pwNotTheSame = false;
    pwFieldEmpty = true;
    isHelpTextActive = false;
    resetSuccess = false;
    isTokenValid = false;
    pwToShort = false;
    // 0 = invalid token
    // 1 = valid token
    token = '';
    tenant = '';
    language = '';

    currentLocale: Locale;
    currentLanguage: Locale;

    version = {
        dirty: true,
        raw: '0.8.2-220-g9d7b762-dirty',
        hash: 'g9d7b762',
        distance: 220,
        tag: '0.8.2',
        semver: undefined,
        suffix: '220-g9d7b762-dirty',
        semverString: '0.8.2+220.g9d7b762',
    };

    constructor(
        private localeService: LocaleService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.pwResetForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            password2: ['', [Validators.required, Validators.minLength(8)]],
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['token'] && params['lang'] && params['tenant']) {
                this.token = params['token'];
                this.language = params['lang'];
                this.tenant = params['tenant'];
            } else {
                // TODO: some error thingy
            }
        });

        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLanguage = locale;
        });

        // this.selectLanguage(this.currentLanguage)
        this.authenticationService
            .checkIfResetPasswordTokenValid<tokenData>({
                token: this.token,
            })
            .pipe(map((res) => res))
            .subscribe((res) => {
                if (res.toString() == '0') {
                    this.isTokenValid = false;
                }
                if (res.toString() == '1') {
                    this.isTokenValid = true;
                }
            });
    }

    pwReset = {
        password: {
            active: false,
            type: 'password' as 'text' | 'password',
        },
        password2: {
            active: false,
            type: 'password' as 'text' | 'password',
        },
    };

    get f() {
        return this.pwResetForm.controls;
    }

    checkIfSamePW(event: any, number: any) {
        const pwLenght = event.target.value;

        if (pwLenght.length > 7 && number == 1) {
            this.pwToShort = false;
        } else if (pwLenght.length <= 7 && number == 1) {
            this.pwToShort = true;
        }
        if (this.pwResetForm.value.password == '') {
            this.pwFieldEmpty = false;
        } else {
            this.pwFieldEmpty = true;
        }
        if (this.pwResetForm.value.password == this.pwResetForm.value.password2) {
            this.pwNotTheSame = true;
        } else {
            this.pwNotTheSame = false;
        }
    }

    activatedHelpText() {
        this.isHelpTextActive = true;
    }

    SubmitPwReset() {
        this.submitted = true;

        this.authenticationService
            .resetPasswordbyHand<pwData>(
                {
                    password: this.pwResetForm.controls['password'].value,
                    token: this.token,
                },
                this.tenant
            )
            .pipe(map((res) => res))
            .subscribe((res) => {
                if (res.toString() == '0') {
                    this.resetSuccess = true;
                }
            });
    }

    inputFocusHandler(fieldName: 'password2' | 'password'): void {
        const existInputField = this.pwReset[fieldName];
        if (existInputField) {
            existInputField.active = true;
        }
    }

    inputBlurHandler(fieldName: 'password2' | 'password'): void {
        const existInputField = this.pwReset[fieldName];
        if (existInputField && !this.f[fieldName].value.trim()) {
            existInputField.active = false;
        }
    }

    togglePasswordFieldInputType() {
        this.pwReset.password.type = this.pwReset.password.type === 'text' ? 'password' : 'text';
        this.pwReset.password2.type = this.pwReset.password2.type === 'text' ? 'password' : 'text';
    }

    // selectLanguage(locale: Locale) {
    //     this.localeService.$currentLocale
    //     this.localeService.changeLocale(locale)
    // }
}

type pwData = {
    password: string;
    token: string;
};
type tokenData = {
    token: string;
};
