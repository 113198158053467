import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule, ColourThemePipe],
})
export class PopUpComponent implements OnInit {
    @Input() isOpen: boolean;
    @Input() titleText: string;
    @Input() bodyText: string;
    @Input() positiveButtonText: string;
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter();
    @Output() performAction: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    close() {
        this.isOpenChange.emit(false);
    }

    performPositive() {
        this.performAction.emit(true);
    }
}
