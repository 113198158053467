export enum TransactionType {
    EMPLOYER_ADMISSION = 'EMPLOYER_ADMISSION',
    EMPLOYER_TERMINATION = 'EMPLOYER_TERMINATION',
    EMPLOYER_MUTATION_SALARY_NEW = 'EMPLOYER_MUTATION_SALARY_NEW',
    EMPLOYER_MUTATION_SALARY_DELETION = 'EMPLOYER_MUTATION_SALARY_DELETION',
    EMPLOYEE_MUTATION_ADDRESS = 'EMPLOYEE_MUTATION_ADDRESS',
    EMPLOYEE_PURCHASE = 'EMPLOYEE_PURCHASE',
    EMPLOYEE_ACCOUNT_ACTIVATION = 'EMPLOYEE_ACCOUNT_ACTIVATION',
    EMPLOYER_MUTATION_SALARY = 'EMPLOYER_MUTATION_SALARY',
    EMPLOYER_MUTATION_ADDRESS = 'EMPLOYER_MUTATION_ADDRESS',
    EMPLOYER_BULK_MUTATIONS = 'EMPLOYER_BULK_MUTATIONS',
    EMPLOYEE_DELETION_ADDRESS = 'EMPLOYEE_DELETION_ADDRESS',
    BOARDMEMBER_MUTATION_ADDRESS = 'BOARDMEMBER_MUTATION_ADDRESS',
    AKTRANSACTION = 'AKTRANSACTION',
    AKTRANSACTION_CANCELLATION = 'AKTRANSACTION_CANCELLATION',
    AKTRANSACTION_TERMINATION = 'AKTRANSACTION_TERMINATION',
    PAIN = 'PAIN',
    PAYMENTDETAILS = 'PAYMENTDETAILS',
    PERSON_MUTATION = 'PERSON_MUTATION',
    PENSION_SIMULATION = 'PENSIMMULTISTEP',
    CALCULATION_JAS_JNB = 'CALCULATION_JAS_JNB',
    CALCULATION_JNB_JAS_DELETION = 'CALCULATION_JNB_JAS_DELETION',
}
