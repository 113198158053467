// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LocaleData } from 'libs/shared/src/lib/models/interfaces/locale-data';

const localeData: LocaleData = {
    defaultLocale: 'de',
    locales: ['en', 'de', 'fr' /*, 'it'*/],
};

export const environment = {
    production: false,
    hmr: false,
    localeData,
    azureId: false,
    groupId: 'a69dd98c-a0ec-4851-a0f4-ebe02159a16e',
    emailServiceFrom: 'service@swarm.cloud',
    //authApiUrl: 'http://localhost:8182',
    authApiUrl: 'https://dockerrun.claninfo.ch:5808/auth-demo',
    clanDataApiUrl: 'https://dockerrun.claninfo.ch:5808/api-demo/dataservice',
    //clanDataApiUrl: 'http://localhost:5800/dataservice',
    authWsUri: 'ws://swarm-api-gateway-haiz-swarm-staging.ch-gva-2.exo.appuioapp.ch/ws',
    encryptionKey: '4eDxl3GkkOLSMFSt6QUjjceXb033YPDYsY7QqWCx',
    baseTimeout: 1800,
    reportPath:
        '?mode=sync&ArchivPfad=%2F%2Fterravic%2FClanData%2FXOBatchOutput%2FVAK%2F&Saldo={{updatedBalance}}&QRcodeServlet=http%3A%2F%2Fterravic%3A8090%2Fxo-server%2FXoBarcode%3Ftype%3Ddatamatrix%26fmt%3Dpng%26msg%3D&ProtectedPfad=protected%2F&Valuta={{valueDate}}&PicPfad=%2F%2Fterravic%2FClanData%2FBilder%2F&ReportBaseDir=c%3A%2FProgram+Files%2Fclaninfo%2Freports&Belegtext={{bookingType}}&OptBeguenstigter={{optBeguenstigter}}&DEVICE=%2F%2Fterravic%2FClanData%2FXOBatchOutput&AkKontoNummer={{accountNumber}}&FORMAT=PDF&Administration=ADMIN&VsNum={{vsNum}}&OptKundenkopie={{customerCopyStr}}&Betrag={{amount}}&BriefID=AK01&sessionid=EBF7BA954FFF4CFA92110A5D0AFAD84B&report=C%3A%2FProgram+Files%2Fclaninfo%2Freports%2Fschalterbeleg.xorep',
    statementReportPath:
        '?mode=sync&BriefID={{reportId}}&{{procNr}}VsNum={{vsNum}}&PicPfad=%2F%2Fterravic%2FClanData%2FBilder%2F&DatVon={{dateFrom}}&ProtectedPfad=protected%2F&FORMAT=PDF&ArchivPfad=%2F%2Fterravic%2FClanData%2FXOBatchOutput%2FVAK%2F&DatBis={{dateTo}}&DEVICE=%2F%2Fterravic%2FClanData%2FXOBatchOutput&Administration=ADMIN&QRcodeServlet=http%3A%2F%2Fterravic%3A8090%2Fxo-server%2FXoBarcode%3Ftype%3Ddatamatrix%26fmt%3Dpng%26msg%3D&ReportBaseDir=c%3A%2FProgram+Files%2Fclaninfo%2Freports&sessionid=C9224780A5974C398917B2ACEC63C518&report=C%3A%2FProgram+Files%2Fclaninfo%2Freports%2Fversbrief_ak.xorep',
    qrPurchaseReportPath:
        '?mode=sync&VsNum={{vsNum}}&Betrag={{amount}}&Belegtext={{paymentInfo}}&FORMAT=PDF&ArchivPfad=%2F%2Fterravic%2FClanData%2FXOBatchOutput%2FVPK%2F&Administration=ADMIN&ReportBaseDir=c%3A%2FProgram+Files%2Fclaninfo%2Freports&sessionid=C9224780A5974C398917B2ACEC63C518&report=C%3A%2FProgram+Files%2Fclaninfo%2Freports%2Fqreinzahlungsschein_vpk.xorep',
    pensionPurchaseReportPath:
        '?mode=sync&BriefID={{reportId}}&procNr={{procNr}}&VsNum={{vsNum}}&pOpt1={{pOpt1}}&pOpt2={{pOpt2}}&pOpt3={{pOpt3}}&pOpt4={{pOpt4}}&pOpt5={{pOpt5}}&pOpt6={{pOpt6}}&pOpt7={{pOpt7}}&pBetrag1={{pBetrag1}}&pBetrag2={{pBetrag2}}&pBetrag3={{pBetrag3}}&pBetrag4={{pBetrag4}}&pBetrag5={{pBetrag5}}&pEinrichtung1={{pEinrichtung1}}&pEinrichtung2={{pEinrichtung2}}&pEinrichtung3={{pEinrichtung3}}&pEinrichtung4={{pEinrichtung4}}&pEinrichtung5={{pEinrichtung5}}&pDatum1={{pDatum1}}&pDatum2={{pDatum2}}&pDatum3={{pDatum3}}&pDatum4={{pDatum4}}&pDatum5={{pDatum5}}&FORMAT=PDF&ArchivPfad=%2F%2Fterravic%2FClanData%2FXOBatchOutput%2FVPK%2F&Administration=ADMIN&ReportBaseDir=c%3A%2FProgram+Files%2Fclaninfo%2Freports&sessionid=C9224780A5974C398917B2ACEC63C518&report=C%3A%2FProgram+Files%2Fclaninfo%2Freports%2FFORM_Einkauf_vpk.xorep',
    ENV: 'demo',
    portalLibsVersion: '2.0.3',
    idleTimeInSeconds: 2,
    countdownTimeInSeconds: 900,
    automaticLogOutActivated: true,
    logo: 'swarm-logo.png',
    DE_PORTAL_TITLE: 'Arbeitnehmerportal',
    EN_PORTAL_TITLE: 'Employee Portal',
    FR_PORTAL_TITLE: 'Portail des employés',
    IT_PORTAL_TITLE: 'Portale dipendenti',
    bannerText: 'Demo Environment',
};
