<div class="Login-Title-Box">
  @if (!showClanLogo) {
    <div class="swarmLogo" [ngClass]="{ light: 1 }"></div>
  }
  @if (showClanLogo) {
    <div class="img-box">
      <img class="loginPage-Image" src="../../../../assets/img/clanLogo/Logo.png" />
    </div>
  }
  @if (showClanLogo) {
    <p class="Login-Text" [ngClass]="{ light: 1 }">
      <strong class="strong">MY-</strong>CLAN
      {{ 'auth.passwordResetTitle' | translate }}
    </p>
  }
</div>

<div class="mainBox">
  <div class="languageSelection">
    <div class="selectionBox">
      @for (item of allLanguages; track item; let i = $index) {
        <p
          class="LanguageSelection"
          [ngClass]="{ light: 1 }"
          [class.reset-password__modal-value--active]="item === currentLocale"
          (click)="selectLanguage(item)"
          >
          {{ item }}
        </p>
      }
    </div>
  </div>
  <div class="loginText" [ngClass]="{ light: 1 }">
    <div class="Box">{{ 'auth.passwordReset' | translate }}</div>
  </div>
  <p class="instructionText" [ngClass]="{ light: 1 }">
    {{ 'auth.passwordResetInstruction' | translate }}
  </p>
  <!-- DropDown -->
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <div class="form-group form-group-tenant">
      <lib-tenant-field
        [tenantControl]="tenantPasswordResetControl"
        (emitSelectedTenantData)="receiveSelectedTenant($event)"
      ></lib-tenant-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'auth.email' | translate }}</mat-label>
      <input matInput formControlName="email" required />
      @if (emailControl.hasError('required')) {
        <mat-error>{{
          'auth.errors.email.required' | translate
        }}</mat-error>
      }
      @if (emailControl.hasError('email')) {
        <mat-error>{{
          'auth.errors.email.incorrect' | translate
        }}</mat-error>
      }
    </mat-form-field>
    <div class="button-group">
      <!-- Button to return to login page -->
      <a class="routerLink" routerLink="/auth" class="backLink">
        <div class="backBtn">
          <span style="top: 50%">{{ 'cabinet.profile.buttons.back' | translate }}</span>
        </div>
      </a>
      <button type="submit" class="submit-button" [disabled]="resetPasswordForm.invalid">
        {{ 'auth.resetEmail.sendEmail' | translate }}
      </button>
    </div>
    @if (noTenantSelected) {
      <p class="errorText">
        {{ 'auth.errors.register.noTenantSelected' | translate }}
      </p>
    }
    @if (resetBtnPressed) {
      <p class="passwordResetText">
        {{ 'auth.passwordResetText' | translate }}
      </p>
    }
    @if (!isUserPersisten) {
      <p class="errorText">
        {{ 'auth.errors.user.userNotFound' | translate }}
      </p>
    }
  </form>
  <!-- <div class="version">
  Build: {{ version.semverString }} -->
  <!--({{ version.date}})-->
<!-- </div> -->
</div>
