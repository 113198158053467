import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from '../../../app/material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { CMRoutingModule } from './cm-routing.module';
import { CMMainComponent } from './components/cm-main/cm-main.component';
import { ContentViewComponent } from './components/content-view/content-view.component';
import { ContentWizardComponent } from './components/content-wizard/content-wizard.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';

@NgModule({
    exports: [ContentViewComponent],
    imports: [
        MatIconModule,
        MatTableModule,
        MatButtonModule,
        FormsModule,
        CommonModule,
        CMRoutingModule,
        MatProgressSpinnerModule,
        TranslateModule,
        SharedModule,
        MaterialModule,
        MatFormFieldModule,
        MatTabsModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        AngularEditorModule,
        ImageCropperModule,
        MatDialogModule,
        CMMainComponent,
        ContentWizardComponent,
        ContentViewComponent,
        ImageCropperDialogComponent,
    ],
    // providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
})
export class CMModule {}
