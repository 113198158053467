import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocaleService } from './locale.service';
import { AuthenticationService, SSOService } from 'libs/clan-auth/src/public-api';
import { Assignee } from 'libs/shared/src/lib/models/enums/assignee';
import { RoleType } from 'libs/shared/src/lib/models/enums/role-type';
import { TransactionType } from 'libs/shared/src/lib/models/enums/transaction-type';
import { Company } from 'libs/shared/src/lib/models/interfaces/company';
import { Foundation } from 'libs/shared/src/lib/models/interfaces/foundation';
import { PensionFund } from 'libs/shared/src/lib/models/interfaces/pension-fund';
import { Person } from 'libs/shared/src/lib/models/interfaces/person';
import { Role } from 'libs/shared/src/lib/models/interfaces/role';
import { SavingPlan } from 'libs/shared/src/lib/models/interfaces/saving-plan';
import { SavingPlanResponse } from 'libs/shared/src/lib/models/interfaces/saving-plan-response';
import { SimulationCertificate } from 'libs/shared/src/lib/models/interfaces/simulation-certificate';
import { SimulationInsuranceCertificateTransaction } from 'libs/shared/src/lib/models/interfaces/simulation-insurance-certificate-transaction';
import { SimulationPurchase } from 'libs/shared/src/lib/models/interfaces/simulation-purchase';
import { Termination } from 'libs/shared/src/lib/models/interfaces/termination';
import { TransactionResponse } from 'libs/shared/src/lib/models/interfaces/transaction-response';
import { WefSimEndResponse } from 'libs/shared/src/lib/models/interfaces/wef-sim-end-response';
import { WefSimExecute } from 'libs/shared/src/lib/models/interfaces/wef-sim-execute';
import { WefSimStart } from 'libs/shared/src/lib/models/interfaces/wef-sim-start';
import { WefSimStartResponse } from 'libs/shared/src/lib/models/interfaces/wef-sim-start-response';
import { RetirementExecute } from 'libs/shared/src/lib/models/interfaces/retirement-execute';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { Address } from 'libs/shared/src/lib/models/interfaces/address';
import {
    ClanMyData,
    ClanMyDataPersonalInformation,
    Insurances,
} from 'libs/shared/src/lib/models/interfaces/clan-my-data';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    // Todo: all requests should use the same url endpoint
    private twoFA = environment.authApiUrl;
    private clanMyData: ReplaySubject<ClanMyData> = new ReplaySubject<ClanMyData>(1);
    private pensionFund: ReplaySubject<PensionFund> = new ReplaySubject<PensionFund>();
    private foundation: ReplaySubject<Foundation> = new ReplaySubject<Foundation>(1);
    private currentPerson: ReplaySubject<Person> = new ReplaySubject<Person>(1);
    private company: ReplaySubject<Company> = new ReplaySubject<Company>(1);

    private selectedCompaniesData: Role[];
    private personsListData: Person[];
    private companyPersonsList: ReplaySubject<Person[]> = new ReplaySubject<[]>();
    private selectedCompanies: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);
    private allCompanies: ReplaySubject<Role[]> = new ReplaySubject<Role[]>();
    private companyNameMap: ReplaySubject<Map<string, string>> = new ReplaySubject<Map<string, string>>();
    private isNotPensioner: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);
    private isEmployer: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);
    private isEmployee: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);
    private hasAK: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);

    constructor(
        private http: HttpClient,
        private localeService: LocaleService,
        private authenticationService: AuthenticationService
    ) {}

    get $allCompanies(): Observable<Role[]> {
        return this.allCompanies.asObservable();
    }

    get $companyNameMap(): Observable<Map<string, string>> {
        return this.companyNameMap.asObservable();
    }

    get $clanMyData(): Observable<ClanMyData> {
        return this.clanMyData.asObservable();
    }

    get $pensionFund(): Observable<PensionFund> {
        return this.pensionFund.asObservable();
    }

    get $foundation(): Observable<Foundation> {
        return this.foundation.asObservable();
    }

    get $currentPerson(): Observable<Person> {
        return this.currentPerson.asObservable();
    }

    get $company(): Observable<Company> {
        return this.company.asObservable();
    }

    get $pensionFundName(): Observable<string> {
        return this.$pensionFund.pipe(
            map((fund) => {
                return fund.name;
            })
        );
    }

    get $companyObject(): Observable<Company> {
        return this.$company.pipe(
            map((data) => {
                return data;
            })
        );
    }
    get apiUrl(): string {
        return sessionStorage.getItem('api-url');
    }

    get $userData(): Observable<ClanMyDataPersonalInformation> {
        return this.$clanMyData.pipe(
            map((clanMyData) => {
                clanMyData.personalInformation.yearOfBirth = moment(clanMyData.personalInformation.dateOfBirth).year();
                clanMyData.personalInformation.age =
                    moment().year() - moment(clanMyData.personalInformation.dateOfBirth).year();
                return clanMyData.personalInformation;
            })
        );
    }

    get $currentUserData(): Observable<Person> {
        return this.$currentPerson.pipe(
            map((data) => {
                return data;
            })
        );
    }

    get $insurances(): Observable<Insurances[]> {
        return this.$clanMyData.pipe(map((clanMyData) => clanMyData.insurances));
    }

    get isNotPensioner$(): Observable<boolean> {
        return this.isNotPensioner.asObservable();
    }

    get isEmployer$(): Observable<boolean> {
        return this.isEmployer.asObservable();
    }

    get isEmployee$(): Observable<boolean> {
        return this.isEmployee.asObservable();
    }

    get hasAK$(): Observable<boolean> {
        return this.hasAK.asObservable();
    }

    setHasAK(value) {
        this.hasAK.next(value);
    }

    private getMyData() {
        return this.http.get<ClanMyData>(`${this.apiUrl}/mydata`);
    }

    public getCurrentPerson() {
        return this.http.get<Person>(`${this.apiUrl}/mydata`);
    }

    private getPensionFund(pensionFundId) {
        return this.http.get<PensionFund>(`${this.apiUrl}/pension-funds/${pensionFundId}`);
    }

    private getFoundation(foundationId: string) {
        return this.http.get<Foundation>(`${this.apiUrl}/foundations/${foundationId}`);
    }

    getCompany(companyId) {
        return this.http.get<Company>(`${this.apiUrl}/companies/${companyId}`);
    }
    private getAllCompanies() {
        return this.http.get<Company[]>(`${this.apiUrl}/companies`);
    }
    getSimulationCertificate(transaction: SimulationInsuranceCertificateTransaction) {
        return this.http.post<SimulationCertificate>(`${this.apiUrl}/simulations/certificate`, transaction);
    }

    getSimulationRetirement(transaction: SavingPlan) {
        return this.http.post<WefSimExecute>(`${this.apiUrl}/simulations/retirement`, transaction);
    }

    getSimulationRetirementExecute(transaction: RetirementExecute) {
        return this.http.post<WefSimExecute>(`${this.apiUrl}/simulations/retirement-execute`, transaction);
    }

    getSavingPlan(transaction: SavingPlan) {
        return this.http.post<SavingPlanResponse>(`${this.apiUrl}/simulations/plan`, transaction);
    }

    getTermination(transaction: Termination) {
        return this.http.post<SavingPlanResponse>(`${this.apiUrl}/simulations/termination`, transaction);
    }

    getWef(transaction: WefSimStart) {
        return this.http.post<WefSimStartResponse>(`${this.apiUrl}/simulations/wefSimStart`, transaction);
    }

    getPreSimulation(transaction: WefSimStart) {
        return this.http.post<WefSimStartResponse>(`${this.apiUrl}/simulations/preSimStart`, transaction);
    }

    getWefExecute(transaction: WefSimExecute) {
        return this.http.post<WefSimEndResponse>(`${this.apiUrl}/simulations/wefSimExecute`, transaction);
    }

    private createEmployeeTransaction(body): Observable<TransactionResponse> {
        return this.http.post<TransactionResponse>(`${this.apiUrl}/transactions`, body);
    }

    getSimulationPurchase(body): Observable<SimulationPurchase> {
        return this.http.post<SimulationPurchase>(`${this.apiUrl}/simulations/purchase`, body);
    }

    checkForPendingEmailChange(email: string) {
        return this.http.get<boolean>(`${this.twoFA}/epwc/pendingEmailChange?email=${email}`);
    }

    // TODO so far unused remove?
    // openSimulationCertificate(): void {
    //     this.getSimulationCertificate().subscribe((res) => {
    //         const link = document.createElement('a');
    //         link.href = `data:${res.Dokument[0].MimeType};base64,${res.Dokument[0].Content}`;
    //         link.download = res.Dokument[0].Name;
    //         link.click();
    //         link.remove();
    //     });
    // }

    getData(): void {
        this.getMyData().subscribe((myData) => {
            this.clanMyData.next(myData);
            const determineStringValueInLocaleType = (value: string): value is Locale => !!(value as Locale);
            if (
                myData.personalInformation.language &&
                determineStringValueInLocaleType(myData.personalInformation.language)
            ) {
                // TODO: remove comment
                // this.localeService.changeLocale(myData.personalInformation.language);
            }
            //TODO: multiple insurances
            if (myData?.insurances?.length > 0 && myData.insurances[0]?.pensionFundId) {
                this.getPensionFund(myData.insurances[0]?.pensionFundId).subscribe((fund) => {
                    this.pensionFund.next(fund);
                });
            }
            if (myData?.insurances?.length > 0 && myData?.insurances[0]?.foundationId) {
                this.getFoundation(myData.insurances[0]?.foundationId).subscribe({
                    next: (foundation) => {
                        this.foundation.next(foundation);
                    },
                    error: (error) => {
                        //TODO: what to do in error case?
                        console.error(error);
                    },
                });
            }
            this.getAllCompanies().subscribe({
                next: (companies) => {
                    let map: Map<string, string> = new Map<string, string>();
                    for (const company of companies) {
                        map.set(company.companyId, company.name);
                    }
                    this.companyNameMap.next(map);
                },
            });
        });

        this.getCurrentPerson().subscribe(
            (data: Person) => {
                this.currentPerson.next(data);

                const parsedToken = SSOService.parseJwt(this.authenticationService.authToken);
                const hasPensionerRole = !!parsedToken.realm_access.roles.find((x) =>
                    x.includes(RoleType.PENSIONER.toLowerCase())
                );
                const hasEmployerRole = !!parsedToken.realm_access.roles.find((x) => {
                    if (x === RoleType.EMPLOYER.toLowerCase()) {
                        return true;
                    }
                });

                const hasEmployeeRole = !!parsedToken.realm_access.roles.find((x) => {
                    if (x === RoleType.EMPLOYEE.toLowerCase()) {
                        return true;
                    }
                });

                this.isNotPensioner.next(hasPensionerRole ? false : true);
                this.isEmployer.next(hasEmployerRole ? true : false);
                this.isEmployee.next(hasEmployeeRole ? true : false);

                if (data?.insurances?.length > 0 && data.insurances[0].companyId) {
                    this.getCompany(data.insurances[0].companyId).subscribe((c) => {
                        this.company.next(c);
                    });
                } else {
                    const firstRole = data.roles.find((role) => role.companyId);
                    const { companyId, pensionFundId, foundationId } = firstRole;
                    this.getCompany(companyId).subscribe((c) => {
                        this.company.next(c);
                    });
                    this.getPensionFund(pensionFundId).subscribe((p) => {
                        this.pensionFund.next(p);
                    });
                    this.getFoundation(foundationId).subscribe((f) => {
                        this.foundation.next(f);
                    });
                }
            },
            (error) => {
                console.log('Error while fetching current person', error);
            }
        );
    }

    // Give the person for originator information and the mutated address
    changeAddressTransaction(person: Person, address: Address): Observable<TransactionResponse> {
        const body = {
            company: person.insurances[0].foundationId,
            tenant: person.insurances[0].foundationId,
            originator: {
                personId: person.personId,
                companyId: person.insurances[0].companyId,
                pensionFundId: person.insurances[0].pensionFundId,
                foundationId: person.insurances[0].foundationId,
            },
            transactionType: TransactionType.EMPLOYEE_MUTATION_ADDRESS,
            assignee: Assignee.PVCLAN,
            data: {
                addresses: [address],
            },
        };
        return this.createEmployeeTransaction(body);
    }

    endPurchaseTransaction(person: Person): Observable<TransactionResponse> {
        const body = {
            originator: {
                personId: person.personId,
                companyId: person.insurances[0].companyId,
                pensionFundId: person.insurances[0].pensionFundId,
                foundationId: person.insurances[0].foundationId,
            },
            transactionType: TransactionType.EMPLOYEE_PURCHASE,
            assignee: Assignee.PVCLAN,
            data: {
                personId: person.personId,
                insurances: [
                    {
                        calculationDate: person.insurances[0].calculationDate,
                        maxPurchase: person.insurances[0].maxPurchase,
                    },
                ],
            },
        };

        return this.createEmployeeTransaction(body);
    }
    public removeSelectedCompany(role: Role): void {
        const i = this.selectedCompaniesData.indexOf(role);
        this.selectedCompaniesData.splice(i, 1);
        this.selectedCompanies.next(Array.from(this.selectedCompaniesData));
    }

    public addSelectedCompany(role: Role): void {
        this.selectedCompaniesData.push(role);
        this.selectedCompanies.next(Array.from(this.selectedCompaniesData));
    }
}
