<div class="vote-card" [ngClass]="{ light: (1 | colourTheme) === false }">
  <div class="vote-card__top card-top" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="card-top__index" [ngClass]="{ light: (1 | colourTheme) === false }">{{ index }}.</div>
    <div class="card-top__title" [ngClass]="{ light: (1 | colourTheme) === false }">
      {{ voteCard.title }}
      <span class="card-top__help">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.3335 4.66668H8.66683V6.00001H7.3335V4.66668Z" fill="#A7A7A7" />
          <path d="M7.3335 7.33334H8.66683V11.3333H7.3335V7.33334Z" fill="#A7A7A7" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.3335 8.00001C1.3335 4.32001 4.32015 1.33334 8.00016 1.33334C11.6802 1.33334 14.6668 4.32001 14.6668 8.00001C14.6668 11.68 11.6802 14.6667 8.00016 14.6667C4.32015 14.6667 1.3335 11.68 1.3335 8.00001ZM2.66683 8.00001C2.66683 10.94 5.06014 13.3333 8.00016 13.3333C10.9402 13.3333 13.3335 10.94 13.3335 8.00001C13.3335 5.06001 10.9402 2.66668 8.00016 2.66668C5.06014 2.66668 2.66683 5.06001 2.66683 8.00001Z"
            fill="#A7A7A7"
            />
        </svg>
      </span>
    </div>
  </div>

  @if (voteCard.proposal || voteCard.explanation) {
    <div class="vote-card__content card-content">
      @if (voteCard.proposal) {
        <div class="card-content__item card-content__item--proposal content-item">
          <div class="content-item__title" [ngClass]="{ light: (1 | colourTheme) === false }">
            {{ 'cabinet.home.sidebar.voteCard.proposal' | translate }}
          </div>
          <div class="content-item__text" [ngClass]="{ light: (1 | colourTheme) === false }">
            {{ voteCard.proposal }}
          </div>
        </div>
      }
      @if (voteCard.explanation) {
        <div class="card-content__item card-content__item--explanation content-item">
          <div class="content-item__title" [ngClass]="{ light: (1 | colourTheme) === false }">
            {{ 'cabinet.home.sidebar.voteCard.explanation' | translate }}
          </div>
          <div class="content-item__text" [ngClass]="{ light: (1 | colourTheme) === false }">
            {{ voteCard.explanation }}
          </div>
        </div>
      }
    </div>
  }

  <div class="vote-card__actions card-actions">
    @for (choice of voteChoices; track choice) {
      <div class="card-actions__item" (click)="choiceClick(choice)" [ngStyle]="{}">
        <!-- <img [src]="getChoiceImage(choice)" alt="" /> -->
        <app-vote-meter
          [statusColor]="choice.value"
          [isActive]="choice.selected"
          [percent]="choice.percent"
        ></app-vote-meter>
      </div>
    }
  </div>
</div>
