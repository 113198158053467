import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitPhone',
    pure: false,
    standalone: true,
})
export class SplitPhonePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        let res = '';
        if (value.length == 10) {
            value.split('').forEach((el, index) => {
                if (index === 3 || index === 6 || index === 8 || index === 10) {
                    res += ' ';
                }
                res += el;
            });
        } else {
            value.split('').forEach((el, index) => {
                if (index === 3 || index === 5 || index === 8 || index === 10) {
                    res += ' ';
                }
                res += el;
            });
        }
        return res;
    }
}
