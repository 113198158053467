<div class="amount-line">
  @if (currentAmount > 9) {
    <div
      class="amount-line-bubble"
      [ngClass]="{ 'blur-currency': (currentAmount | currencyVisibility) === 'hide' && !isDate }"
      [ngStyle]="{ left: bubblePosition }"
      >
      {{ isDate ? dateBubble : (currentAmount | splitNumberWithComma) }}
    </div>
  }
  <div class="amount-line__line" [ngClass]="{ light: (1 | colourTheme) === false }" (click)="clickLine($event)">
    <div class="amount-line__line--to-current" [ngStyle]="{ width: percentsForCurrent + '%' }"></div>
    <div class="amount-line__line--current" [ngStyle]="{ left: 'calc(' + percentsForCurrent + '% - 10px)' }"></div>
    <div class="amount-line__line--list" id="amount-line-object">
      @for (item of points; track item; let i = $index) {
        <div class="amount-line__line--list_point">
          <div class="amount-line__line--list_point-dot" [ngStyle]="{ left: item.left + '%' }"></div>
          <div
            class="amount-line__line--list_point-text"
                    [ngClass]="{
                        'blur-currency': (item.count | currencyVisibility) === 'hide' && !isDate,
                        light: (1 | colourTheme) === false
                    }"
            [ngStyle]="{ left: item.leftBody }"
            >
            {{ item.count | splitNumberWithComma }}
          </div>
        </div>
      }
    </div>
  </div>
</div>
