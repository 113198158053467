import { Component, OnInit, Input } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'app-vote-meter',
    templateUrl: './vote-meter.component.html',
    styleUrls: ['./vote-meter.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, ColourThemePipe],
})
export class VoteMeterComponent implements OnInit {
    @Input() statusColor = 'yes';
    @Input() isActive = false;
    @Input() percent = 0;

    degree: number = 1.8 * this.percent;
    arrowStyle = '';

    constructor() {}

    ngOnInit(): void {
        this.degree = 1.8 * this.percent;
        this.arrowStyle = `{ transform: rotate(${this.degree}deg) }`;
    }
}
