<div class="page-wrapper">
  <div class="page-title">{{ 'cm.title' | translate | uppercase }}</div>
  <div class="table-filters">
    <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
      <mat-label>{{ 'cm.filters.title' | translate }}</mat-label>
      <input matInput placeholder="Filter" [(ngModel)]="filters.title" (input)="applyFilter()" />
      @if (filters.title) {
        <button mat-icon-button matSuffix (click)="clearDateRange('title')">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>

    <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
      <mat-label>{{ 'cm.filters.render-engine' | translate }}</mat-label>
      <mat-select
        [hideSingleSelectionIndicator]="true"
        [(ngModel)]="filters.renderEngine"
        (selectionChange)="applyFilter()"
        [disabled]="true"
        >
        @for (engine of renderEngines; track engine) {
          <mat-option [value]="engine.value">{{ engine.key }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
      <mat-label>{{ 'cm.filters.create-date' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
        <input
          matStartDate
          formControlName="start"
          [placeholder]="startDateTranslation"
          (dateInput)="applyFilter()"
          />
          <input
            matEndDate
            formControlName="end"
            [placeholder]="endDateTranslation"
            (dateInput)="applyFilter()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          @if (dateRange.value.start || dateRange.value.end) {
            <button
              mat-icon-button
              matSuffix
              (click)="clearDateRange('dateRange')"
              >
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        @if (hasUserEmployerRole) {
          <button
            class="filter-element btn-rounded wider create-button button"
            (click)="openCreateNewContent()"
            >
            {{ 'cm.create-new' | translate }}
          </button>
        }
      </div>

      <div class="table-container">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef class="bold">{{ 'cm.id' | translate | uppercase }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.id }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contentType">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.content-type' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ 'cm.content-types.' + item.contentType | translate | uppercase }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="renderEngine">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.render-engine' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.renderEngine }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.content-title' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.title[locale] ?? item.title.de }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.create-date' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.createdDate | date : 'dd.MM.yyyy HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="editDate">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.edit-date' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.editedDate | date : 'dd.MM.yyyy HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.status' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.status }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="bold">{{
              'cm.actions' | translate | uppercase
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <button mat-icon-button (click)="editContent(item)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteContent(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <mat-paginator
        class="paginator"
        [length]="totalElements"
        [pageSize]="filters.size"
        [pageIndex]="filters.page"
        [pageSizeOptions]="[10, 20, 30, 50, 100]"
        (page)="pageEvent($event)"
      ></mat-paginator>
    </div>
