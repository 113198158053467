import { Component, OnInit, Input } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'app-common-button',
    templateUrl: './common-button.component.html',
    styleUrls: ['./common-button.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, ColourThemePipe],
})
export class CommonButtonComponent implements OnInit {
    @Input() text: string;
    @Input() disabled = false;
    @Input() transparent = false;
    @Input() height: number;
    @Input() width: number;
    @Input() noBorder = false;
    @Input() fontSize: number;
    @Input() fontWeight: number | 'normal' | 'medium' | 'bold';

    constructor() {}

    get buttonSize() {
        return {
            width: this.width ? `${this.width}px` : '100%',
            height: this.height ? `${this.height}px` : '100%',
        };
    }

    ngOnInit(): void {}
}
