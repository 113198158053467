<app-environment-banner></app-environment-banner>
<div class="navigation-menu">
  @if (isAuthenticated) {
    <app-navigation-menu
      style="background-color: white"
      [@openClose]="isOpen ? 'open' : 'closed'"
      (navigationToggle)="toggleNavigation($event)"
    ></app-navigation-menu>
  }

  <div class="navigation-menu__content-wrapper">
    @if (isAuthenticated) {
      <app-user-header></app-user-header>
    }
    <router-outlet></router-outlet>
  </div>
</div>
@if (isLoading$ | async) {
  <div class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
}
