import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HTTPStatus {
    private isLoading$: BehaviorSubject<boolean>;
    constructor() {
        this.isLoading$ = new BehaviorSubject(false);
    }

    setLoader(inFlight: boolean) {
        this.isLoading$.next(inFlight);
    }

    getLoader(): Observable<boolean> {
        return this.isLoading$.asObservable();
    }
}
