import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../../../app/modules/ak/services/snackbar.service';
import { AuthenticationService } from 'libs/clan-auth/src/public-api';

export enum IdleUserTimes {
    IdleTimeInSeconds = environment.idleTimeInSeconds, // after this long user will start with idle mode
    CountdownTimeInSeconds = environment.countdownTimeInSeconds, // after this long in idle mode user will be logged out
}

@Injectable({
    providedIn: 'root',
})
export class IdleService {
    private timeoutId: any;
    private countdownId: any;
    private countdownValue: number;

    constructor(
        private _snackbarService: SnackbarService,
        private _translateService: TranslateService,
        private authService: AuthenticationService,
        private router: Router
    ) {}

    reset() {
        clearTimeout(this.timeoutId);
        clearTimeout(this.countdownId);
        this.startIdleTimer();
    }

    startIdleTimer() {
        this.timeoutId = setTimeout(() => {
            this.startCountdown();
        }, IdleUserTimes.IdleTimeInSeconds * 1000);
    }

    startCountdown() {
        if (this.router.url.includes('auth')) return;
        this.countdownValue = IdleUserTimes.CountdownTimeInSeconds;
        this.countdownId = setInterval(() => {
            this.countdownValue--;
            if (this.countdownValue <= 0) {
                clearInterval(this.countdownId);
                this.logout();
            }
        }, 1000);
    }

    logout() {
        this._snackbarService.handleOpenSnackbar(
            this._translateService.instant('cabinet.notifications.loggedOutDueToInactivity'),
            'Close',
            'error'
        );
        const redirectUrl = sessionStorage.getItem('default_tenant_login_url');
        this.authService.logout().subscribe(() => {
            this.router.navigateByUrl(`auth${redirectUrl}`);
        });
    }
}
