import { Component, OnInit, Input } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-more-button',
    templateUrl: './more-button.component.html',
    styleUrls: ['./more-button.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule, ColourThemePipe],
})
export class MoreButtonComponent implements OnInit {
    @Input() withArrow: boolean;

    constructor() {}

    ngOnInit(): void {}
}
