import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'clan-auth';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(@Inject(AuthenticationService) private authentication: AuthenticationService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authentication.isValid()) {
            return true;
        } else {
            this.authentication.removeFromStorage();
            this.router.navigate(['/auth'], {
                queryParams: {
                    redirectFrom: state.url,
                },
            });
        }
    }
}
