import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalEventsService } from '../../../app/services/global/global-events-service';
import { AuthenticationService } from 'libs/clan-auth/src/public-api';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private globalEventsService: GlobalEventsService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.globalEventsService.setIsAuthenticated(this.authenticationService.isValid());

        return next.handle(request);
    }
}
