import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication.service';
import { TenantService } from '../../tenant.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { Directory } from '../../model/Directory';
import { RegisterRequest } from '../../model/RegisterRequest';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { TenantFieldComponent } from '../../shared/components/tenant-field/tenant-field.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lib-auth-registration-form',
    templateUrl: './auth-registration-form.component.html',
    styleUrls: ['./auth-registration-form.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TenantFieldComponent,
    MatFormField,
    MatTooltip,
    MatLabel,
    MatInput,
    MatError,
    MatIcon,
    RouterLink,
    TranslateModule
],
})
export class AuthRegistrationFormComponent implements OnInit, OnDestroy {
    @ViewChild('tenantElement') tenantElement: ElementRef;
    registrationFormGroup: FormGroup;

    registrationSuccess = false;
    type = 'password';
    submitted = false;
    regSubmitted = false;
    doesUserAlreadyExist = false;
    tenantRegistrationControl: FormControl = new FormControl('', Validators.required);
    authCodeDosntMatch = false;
    ssn13notFound = false;
    ahvAlreadyActivated = false;
    selectedTenant: string;
    directoriesList = [];
    CityCode = '+41';
    showTenantList = false;
    noTenantSelected = false;
    currentLocale: Locale;
    selectedIndex: number;
    //helptexthover
    hoveringAHV = false;
    hoveringAuthcode = false;
    hoveringMobileNumber = false;
    hoveringpassword = false;

    //Mail
    emailHeaderText: string;
    emailSalutation: string;
    emailBodyText: string;
    emailBodyText2: string;
    emailSecurityText: string;
    emailSupportText: string;
    emailBodyAfterLinkText: string;
    emailRegards: string;
    version: any;

    //new Registartion
    inputFieldsRegistration = {
        personId: {
            active: false,
            type: 'text',
        },

        authCode: {
            active: false,
            type: 'text',
        },
        number: {
            active: false,
            type: 'text',
        },
        email: {
            active: false,
            type: 'text',
        },
        password: {
            active: false,
            type: 'password' as 'text' | 'password',
        },
        tenant: {
            active: false,
            type: 'text',
        },
    };
    paramsSubscription: Subscription;
    tenantParamExists = false;
    City: any = ['+41', '+49', '+39', '+33'];
    url: string;
    allLanguages: Locale[];
    private tenantParam: string;
    constructor(
        private localeService: LocaleService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private tenantService: TenantService,
        private cookieService: CookieService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // this.version = VERSION;
    }

    ngOnInit(): void {
        this.tenantParam = this.route.snapshot.queryParams['tenant'];
        if (!this.tenantParam) {
            this.router.navigate(['/auth']);
        }
        this.version = this.tenantService.getVersion();
        this.allLanguages = this.localeService.localeList;
        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLocale = locale;
        });

        let authCode = '';
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['authCode']) {
                authCode = params['authCode'];
            }
        });
        this.registrationFormGroup = this.formBuilder.group({
            tenant: [''],
            personId: ['', [Validators.required]],
            authCode: [authCode, [Validators.required, Validators.minLength(39)]],
            number: ['', [Validators.required, Validators.minLength(9)]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });

        this.currentLocale = this.cookieService.get('lang') as Locale;

        this.selectLanguage(this.currentLocale);
        this.cookieService.set('lang', this.currentLocale);
        this.paramsSubscription = this.tenantService.getTenantValue().subscribe((newValue) => {
            this.tenantParamExists = newValue ? true : false;
        });
    }
    ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
    }

    selectLanguage(locale: Locale) {
        this.localeService.changeLocale(locale);
        this.tenantService.selectLanguage(locale);
        this.tenantService.languageChanged(locale);
    }

    getActiveIndex(): number {
        return this.tenantService.getActiveIndex();
    }

    get g() {
        return this.registrationFormGroup.controls;
    }
    get password() {
        return this.registrationFormGroup.get('password');
    }
    get personId() {
        return this.registrationFormGroup.get('personId');
    }
    get authCode() {
        return this.registrationFormGroup.get('authCode');
    }
    get number() {
        return this.registrationFormGroup.get('number');
    }
    get email() {
        return this.registrationFormGroup.get('email');
    }
    translate(key: string): string {
        return this.translateService.instant(key);
    }
    receiveSelectedTenant(selectedTenantData: Directory | undefined) {
        if (selectedTenantData?.tenantId) this.selectedTenant = selectedTenantData?.tenantId;
    }

    inputFocusHandlerRegistration(
        fieldName: 'email' | 'password' | 'authCode' | 'personId' | 'number' | 'tenant'
    ): void {
        const existInputField = this.inputFieldsRegistration[fieldName];
        if (existInputField) {
            existInputField.active = true;
            if (fieldName == 'tenant') {
                this.showTenantList = true;
            }
        }
    }

    inputBlurHandlerRegistration(
        fieldName: 'email' | 'password' | 'authCode' | 'personId' | 'number' | 'tenant'
    ): void {
        const existInputField = this.inputFieldsRegistration[fieldName];
        if (existInputField && !this.g[fieldName].value.trim()) {
            existInputField.active = false;
        }
    }

    togglePasswordFieldInputType() {
        this.inputFieldsRegistration.password.type =
            this.inputFieldsRegistration.password.type === 'text' ? 'password' : 'text';
    }

    async onSubmitRegistration() {
        if (!this.selectedTenant) {
            this.noTenantSelected = true;
            return;
        }

        this.url = window.location.host;
        this.regSubmitted = true;
        this.doesUserAlreadyExist = false;
        this.ssn13notFound = false;
        this.authCodeDosntMatch = false;
        this.ahvAlreadyActivated = false;
        const translations = this.translateService.instant('auth.registrationEmailActivation');
        this.emailHeaderText = translations.header;
        this.emailSalutation = translations.salutation;
        this.emailBodyText = translations.body;
        this.emailBodyText2 = translations.body2;
        this.emailSupportText = translations.support;
        this.emailSecurityText = translations.security;
        this.emailBodyAfterLinkText = translations.bodyAfterLink;
        this.emailRegards = translations.regards;
        this.createUser();
    }

    checkPassword() {
        // let pw = this.registrationFormGroup.controls['password'].value;
        // TODO: Is password length checked?
    }

    createUser() {
        const registerRequest = new RegisterRequest();
        registerRequest.ahvNumber = this.registrationFormGroup.controls['ahvNumber'].value;
        registerRequest.authCode = this.registrationFormGroup.controls['authCode'].value;
        registerRequest.phoneNumber = this.CityCode + this.registrationFormGroup.controls['number'].value;
        registerRequest.enabled = true;
        registerRequest.login = this.registrationFormGroup.controls['email'].value;
        registerRequest.password = this.registrationFormGroup.controls['password'].value;
        registerRequest.emailHeader = this.emailHeaderText;
        registerRequest.emailSalutation = this.emailSalutation;
        registerRequest.emailBody = this.emailBodyText;
        registerRequest.emailBody2 = this.emailBodyText2;
        registerRequest.emailSecurity = this.emailSecurityText;
        registerRequest.emailSupport = this.emailSupportText;
        registerRequest.emailAfterLinkBody = this.emailBodyAfterLinkText;
        registerRequest.emailRegards = this.emailRegards;
        registerRequest.lang = this.currentLocale.toString();
        registerRequest.url = this.url;

        this.authenticationService
            .createUser<AuthRegisterCreate>(registerRequest, this.selectedTenant)
            .pipe(map((res) => res))
            .subscribe({
                next: () => {
                    this.registrationSuccess = true;
                },
                error: (error) => {
                    if (error.status == '409') {
                        this.doesUserAlreadyExist = true;
                    }
                    if (error.status == '4404') {
                        this.authCodeDosntMatch = true;
                    }
                    if (error.status == '4413') {
                        this.ssn13notFound = true;
                    }
                    if (error.status == '4409') {
                        this.ahvAlreadyActivated = true;
                    }
                },
            });
    }

    changeCity(e: any) {
        this.CityCode = e.target.value;
    }
    toggleVisibility(type: any) {
        this.type = type;
        this.type = this.type === 'password' ? 'text' : 'password';
    }
}

type AuthRegisterCreate = {
    personId: string;
    authCode: string;
    phoneNumber: number;
    enabled: boolean;
    login: string;
    password: string;
    emailHeader: string;
    emailSalutation: string;
    emailBody: string;
    emailBody2: string;
    emailSecurity: string;
    emailSupport: string;
    emailAfterLinkBody: string;
    emailRegards: string;
    lang: string;
    url: string;
};
