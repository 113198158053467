import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFeedCard } from '../../../../app/modules/home/services/my-swarm-feed.service';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonButtonComponent } from '../common-button/common-button.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { VoteCardComponent } from '../vote-card/vote-card.component';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'app-feed-card',
    templateUrl: './feed-card.component.html',
    styleUrls: ['./feed-card.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        VoteCardComponent,
        CheckboxComponent,
        CommonButtonComponent,
        TranslateModule,
        ColourThemePipe,
    ],
})
export class FeedCardComponent implements OnInit {
    @Input() feedCard: IFeedCard;

    @Output() voteCard = new EventEmitter<IFeedCard>();

    @Output() addToFavorite = new EventEmitter<string>();

    voteListVisibility = false;

    constructor() {}

    voteButtonClick(feedCard: IFeedCard): void {
        this.voteCard.emit(feedCard);
        this.toggleVoteListVisibility(true);
    }

    toggleVoteListVisibility(value: any): void {
        this.voteListVisibility = value;
    }

    addCardToFavorite(feedCardTitle: string): void {
        this.addToFavorite.emit(feedCardTitle);
    }

    ngOnInit(): void {}
}
