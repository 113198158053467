import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, combineLatest, map, Observable, of } from 'rxjs';
import { GlobalEventsService } from '../../../app/services/global/global-events-service';
import { CustomConfigurationService } from '../services/custom-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class CabinetGuard implements CanActivate {
    constructor(
        private customConfigurationService: CustomConfigurationService,
        private globalEventsService: GlobalEventsService,
        private router: Router
    ) {}

    private isInPilotGroup$ = this.customConfigurationService.isInPilotGroup$;
    private hasCabinet$ = this.globalEventsService.hasCabinet$;

    canActivate(): Observable<boolean> {
        return combineLatest([this.isInPilotGroup$, this.hasCabinet$]).pipe(
            map(([isInPilotGroup, hasCabinet]) => {
                if (hasCabinet || isInPilotGroup) {
                    return true;
                }

                this.router.navigate(['/home']);
                return false;
            }),
            catchError((error) => {
                this.router.navigate(['/home']);
                return of(false);
            })
        );
    }
}
