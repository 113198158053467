import { Locale } from './../../../shared/src/lib/models/types/locale';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SSOService } from './sso.service';
import { Directory } from './model/Directory';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    private activeIndex = -1;
    all = [];
    private ssoService: SSOService;
    selectedTenantData: any;
    selectedTenant: string | undefined;
    private selectedTenantSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    selectedTenantData$: Observable<Directory> = this.selectedTenantSubject.asObservable();
    private sortedDirectories: ReplaySubject<Directory[]> = new ReplaySubject<Directory[]>(1);
    showTenantList = false;
    private languageSelectedSubject = new Subject<Locale>();
    languageSelected$: Observable<Locale> = this.languageSelectedSubject.asObservable();
    private tenantSubject = new BehaviorSubject<string>('');
    refresher$ = new BehaviorSubject(null);
    registrationClicked = false;
    forgotPasswordClicked = false;
    constructor(private router: Router, protected activatedRoute: ActivatedRoute) {
        combineLatest([this.activatedRoute.queryParamMap, this.refresher$])
            .pipe(map((data) => data[0]))
            .subscribe((params) => {
                const tenantParam = params.get('tenant');

                this.tenantSubject.next(tenantParam ?? '');
            });
    }
    updateTenantValue(newValue: string) {
        // Manually update the tenant value
        this.tenantSubject.next(newValue);
    }

    getTenantValue() {
        // Return the BehaviorSubject as an observable
        return this.tenantSubject.asObservable();
    }

    refreshTenantValue() {
        // Trigger a refresh of the subscription
        this.refresher$.next(null);
    }

    getSelectedTenant() {
        return this.selectedTenant ? this.selectedTenant : '';
    }
    languageChanged(locale: Locale) {
        this.languageSelectedSubject.next(locale);
    }

    selectTenantTitle(data: any): void {
        this.selectedTenantSubject.next(data);
        this.selectedTenantData = data;
        this.activeIndex = -1;
    }

    getUserData() {
        return this.selectedTenantData;
    }
    setDirectory(directory: Directory | null) {
        this.selectedTenantData = directory;
    }
    getActiveIndex(): number {
        return this.activeIndex;
    }
    selectLanguage(locale: Locale) {
        this.changeLanguage(locale);
    }

    changeLanguage(currentLocale: Locale): void {
        this.sortedDirectories.next(this.sortDirectories(currentLocale, this.all));
    }
    private sortDirectories(sortByLocale: Locale, directories: Directory[]) {
        const searchColumn = 'name_' + sortByLocale;
        directories.sort((a, b) => {
            const aProp = a[searchColumn];
            const bProp = b[searchColumn];
            if (aProp === undefined || aProp === null || bProp === undefined || bProp === null) {
                return 0;
            }
            return aProp > bProp ? 1 : -1;
        });
        return directories;
    }
    updateUrlParams(selectedTenant: string) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                tenant: selectedTenant,
            },
            queryParamsHandling: 'merge',
        });
    }
    getVersion() {
        return {
            dirty: true,
            raw: '0.8.2-220-g9d7b762-dirty',
            hash: 'g9d7b762',
            distance: 220,
            tag: '0.8.2',
            semver: undefined,
            suffix: '220-g9d7b762-dirty',
            semverString: '0.8.2+220.g9d7b762',
        };
    }
    registerRegistrationClick(clicked: boolean) {
        this.registrationClicked = clicked;
    }
    registerForgotPasswordClicked(clicked: boolean) {
        this.forgotPasswordClicked = clicked;
    }
    getRegistrationClick() {
        return this.registrationClicked;
    }
    getForgotPasswordClick() {
        return this.forgotPasswordClicked;
    }
}
