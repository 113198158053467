<mat-form-field appearance="outline">
  <mat-label> {{ 'auth.tenant' | translate }}</mat-label>
  <input matInput [formControl]="tenantControl" aria-label="Number" matInput [matAutocomplete]="auto" />
  <mat-autocomplete #auto="matAutocomplete" class="list">
    @for (directory of directoriesList; track directory) {
      <mat-option
        (onSelectionChange)="selectTenantTitle(directory)"
        [value]="getLocalizedTenantName(directory, currentLocale)"
        >
        {{ getLocalizedTenantName(directory, currentLocale) }}
      </mat-option>
    }
  </mat-autocomplete>
  @if (tenantControl.hasError('required')) {
    <mat-error>
      {{ 'auth.errors.register.noTenantSelected' | translate }}
    </mat-error>
  }
</mat-form-field>
