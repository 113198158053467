import { Component, OnInit, ElementRef, HostListener, OnDestroy, Inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { VisualisationService } from '../../../../../app/core/services/visualisation.service';
import { LocaleService } from '../../../../../app/core/services/locale.service';
import { Subject, Subscription } from 'rxjs';
import { GlobalEventsService } from '../../../../../app/services/global/global-events-service';
import { CompanyConfigurationService } from '../../../../../app/shared/services/company-configuration.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CustomConfigurationService } from '../../../../../app/core/services/custom-configuration.service';
import { UserService } from '../../../../../app/core/services/user.service';
import { IdleService } from '../../../../../app/core/services/idle.service';
import { Title } from '@angular/platform-browser';
import { AuthObserver, LogoutEvent } from 'libs/clan-auth/src/public-api';
import { CustomConfigType } from 'libs/shared/src/lib/models/enums/custom-config-type';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { LoadingService } from 'libs/shared/src/lib/services/loading.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UserHeaderComponent } from '../user-header/user-header.component';
import { NavigationMenuComponent } from '../navigation-menu/navigation-menu.component';
import { AsyncPipe } from '@angular/common';
import { EnvironmentBannerComponent } from '../environment-banner/environment-banner.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '17%',
                })
            ),
            state(
                'closed',
                style({
                    width: '0.1%',
                })
            ),
            transition('open => closed', [animate('0.5s')]),
            transition('closed => open', [animate('0.5s')]),
        ]),
    ],
    standalone: true,
    imports: [
        EnvironmentBannerComponent,
        NavigationMenuComponent,
        UserHeaderComponent,
        RouterOutlet,
        MatProgressSpinner,
        AsyncPipe,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    showNavigation: boolean;

    isOpen = window.innerWidth < 700 ? false : true;
    isOpenNav = true;
    screenWidth;
    isAuthenticated = false;
    private subscription: Subscription = new Subscription();
    destroyed$ = new Subject<boolean>();
    isLoading$ = this.loadingService.isLoading$.pipe(delay(0));

    constructor(
        private localeService: LocaleService,
        private globalEventsService: GlobalEventsService,
        private router: Router,
        private visualisationService: VisualisationService,
        private authObserver: AuthObserver,
        @Inject(CookieService) private cookieService: CookieService,
        private companyConfigurationService: CompanyConfigurationService,
        private userService: UserService,
        private customConfigurationService: CustomConfigurationService,
        private idleService: IdleService,
        private _elementRef: ElementRef,
        private title: Title,
        private loadingService: LoadingService
    ) {
        this.subscription.add(
            this.globalEventsService.isAuthenticated.subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            })
        );

        this.authObserver.observer.subscribe({
            next: (value) => {
                if (value instanceof LogoutEvent) {
                    this.globalEventsService.setIsAuthenticated(false);
                    this.isAuthenticated = false;
                }
            },
        });
    }

    reset() {
        if (environment.automaticLogOutActivated) {
            this.idleService.reset();
        }
    }

    @HostListener('mousemove') resetListener1() {
        this.reset();
    }
    @HostListener('click') resetListener2() {
        this.reset();
    }
    @HostListener('keypress') resetListener3() {
        this.reset();
    }
    @HostListener('DOMMouseScroll') resetListener4() {
        this.reset();
    }
    @HostListener('mousewheel') resetListener5() {
        this.reset();
    }
    @HostListener('touchmove') resetListener6() {
        this.reset();
    }
    @HostListener('MSPointerMove') resetListener7() {
        this.reset();
    }
    @HostListener('touchstart') resetListener8() {
        this.reset();
    }
    @HostListener('touchmove') resetListener9() {
        this.reset();
    }
    @HostListener('touchend') resetListener10() {
        this.reset();
    }
    @HostListener('touchcancel') resetListener11() {
        this.reset();
    }
    @HostListener('orientationchange') resetListener12() {
        this.reset();
    }
    @HostListener('scroll') resetListener13() {
        this.reset();
    }
    @HostListener('keydown') resetListener14() {
        this.reset();
    }
    @HostListener('keyup') resetListener15() {
        this.reset();
    }

    private isFaqPage(): boolean {
        return this.router.url.includes('faq');
    }

    private isCommunicationCenterPage(): boolean {
        return this.router.url.includes('communication-center');
    }

    private isShowingNavigation(): boolean {
        return !this.isFaqPage() && !this.isCommunicationCenterPage();
    }

    ngOnInit() {
        this._elementRef.nativeElement.removeAttribute('ng-version');
        let language = environment.localeData.defaultLocale;
        this.localeService.$currentLocale.pipe(takeUntil(this.destroyed$)).subscribe((locale) => {
            const localeTitle = environment[`${locale.toUpperCase()}_PORTAL_TITLE`];
            this.title.setTitle(localeTitle);
        });

        if (
            this.cookieService.get('lang') &&
            environment.localeData.locales.includes(this.cookieService.get('lang') as Locale)
        ) {
            language = this.cookieService.get('lang') as Locale;
        }
        this.showNavigation = this.isShowingNavigation();
        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe(() => {
                this.showNavigation = this.isShowingNavigation();
            });

        this.localeService.initTranslate(language);
        if (
            !this.cookieService.get('theme') ||
            (this.cookieService.get('theme') && this.cookieService.get('theme') === 'false')
        ) {
            this.visualisationService.setDarkTheme(false);
            document.body.classList.add('light');
        } else {
            this.visualisationService.setDarkTheme(true);
            document.body.classList.remove('light');
        }
        if (this.isAuthenticated) {
            this.globalEventsService.setIsLoading(true);
            this.customConfigurationService
                .getCustomConfiguration$(CustomConfigType.BOOKING_TYPES)
                .subscribe((bookingTypes) => {
                    this.globalEventsService.setBookingTypes(bookingTypes.config);
                    this.globalEventsService.setIsLoading(false);
                });
            this.userService.$currentPerson.subscribe({
                next: (person) => {
                    this.companyConfigurationService.setCustomConfig(person);
                    this.globalEventsService.setIsLoading(false);
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    toggleNavigation(open: boolean) {
        this.isOpen = open;
    }
}
