import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'LL',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};
@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatTableModule,
        MatDialogModule,
        MatGridListModule,
        MatCardModule,
        MatDividerModule,
        MatDatepickerModule,
        MatMomentDateModule,
    ],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class MaterialModule {}
