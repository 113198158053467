import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    Inject,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SnackbarService } from '../../../../../app/modules/ak/services/snackbar.service';
import { SteppablePageComponent } from '../../../../../app/shared/components/steppable-page/steppable-page.component';
import { CMService } from '../../services/cm.service';
import { EMPTY, Subject, catchError, filter, of, switchMap, takeUntil, tap, zip } from 'rxjs';
import EditorJS from '@editorjs/editorjs';
import Checklist from '@editorjs/checklist';
import Header from '@editorjs/header';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Image from '@editorjs/image';
import InlineCode from '@editorjs/inline-code';
import Link from '@editorjs/link';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import AttachesTool from '@editorjs/attaches';
import { LayoutBlockTool } from 'editorjs-layout';
import { LocaleService } from '../../../../../app/core/services/locale.service';
import Paragraph from '@editorjs/paragraph';
import { MatSelect } from '@angular/material/select';
import {
    createThumbnail,
    getContentThumbnail,
    handleCompressionErrors,
    mapContentToFormData,
    mapFormDataToContent,
} from '../../CMHelperFunctions';
import { UserService } from '../../../../../app/core/services/user.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { CropperDialogData, ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { Content } from 'libs/shared/src/lib/models/interfaces/content';
import { ColourThemePipe } from '../../../../shared/pipes/colour-theme.pipe';
import { ContentViewComponent } from '../content-view/content-view.component';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { NgClass, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-content-wizard',
    templateUrl: './content-wizard.component.html',
    styleUrls: ['./content-wizard.component.scss', '../../../../shared/styles/_global-material-form.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatLabel,
        MatFormField,
        MatSelect,
        MatOption,
        FormsModule,
        ReactiveFormsModule,
        MatInput,
        ContentViewComponent,
        UpperCasePipe,
        TranslateModule,
        ColourThemePipe,
    ],
})
export class ContentWizardComponent extends SteppablePageComponent implements OnInit, OnDestroy, AfterViewInit {
    imageUpdated: boolean = false;

    targetGroups = ['AK', 'PK'];
    contentTypes = ['NEWS', 'PAGE'];
    renderEngines = [
        { key: 'editor.js', value: 'EDITOR' },
        { key: 'survey.js', value: 'SURVEY' },
    ];
    statuses = ['DRAFT', 'PUBLISHED'];
    documentLocations = [
        { id: 1, name: 'Root' },
        { id: 2, name: 'AK Account' },
        { id: 3, name: 'Pension Fund' },
        { id: 4, name: 'Content Management' },
    ];

    contentForm: FormGroup = this.fb.group({
        id: [null],
        de_title: [null, Validators.required],
        en_title: [null],
        fr_title: [null],
        it_title: [null],
        renderEngine: [{ value: this.renderEngines[0], disabled: true }, [Validators.required]],
        contentType: [this.contentTypes[0], [Validators.required]],
        location: [this.documentLocations[0], [Validators.required]],
        locationId: [this.documentLocations[0].id],
        status: [this.statuses[0], [Validators.required]],
        imageThumbnail: [null],
        targetGroup: [null],
        de_content: [null],
        en_content: [null],
        fr_content: [null],
        it_content: [null],
    });

    @ViewChild('editor', { read: ElementRef })
    editorElement: ElementRef;
    private editor: EditorJS;

    @ViewChild('languageSelector') languageSelector: MatSelect;

    contentId;
    locale: Locale;

    @Input()
    content: Content;

    stepElements = [
        {
            id: 1,
            name: 'cabinet.news.wizard.prepareNewsStep.title',
            title: 'cabinet.news.wizard.prepareNewsStep.title',
            active: true,
            passed: false,
        },
        {
            id: 2,
            name: 'cabinet.news.wizard.publishNewsStep.title',
            title: 'cabinet.news.wizard.publishNewsStep.title',
            active: false,
            passed: false,
        },
    ];

    languages = ['de', 'en', 'fr', 'it'];
    secondStepContent: any;
    destroyed$: Subject<boolean> = new Subject<boolean>();
    previousLanguage: string;
    thumbnailChanged: boolean = false;
    private componentRef!: ComponentRef<ImageCropperDialogComponent>;

    constructor(
        private fb: FormBuilder,
        private cmService: CMService,
        private router: Router,
        private snackBarService: SnackbarService,
        private translateService: TranslateService,
        private localeService: LocaleService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(Ng2ImgMaxService) private ng2ImgMaxService: Ng2ImgMaxService,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector
    ) {
        super();
    }
    ngOnInit(): void {
        this.contentForm.controls.location.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
            if (location) this.contentForm.controls.locationId.setValue(location.id);
        });
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params
            .pipe(
                switchMap((params) => {
                    if (params.id) {
                        this.changeDetectorRef.detectChanges();

                        return this.cmService.getContentById(params.id);
                    } else {
                        return of(null);
                    }
                }),
                tap((content: Content) => {
                    if (!content) return;

                    this.content = content;
                    const formData = mapContentToFormData(content, this.renderEngines, this.documentLocations);
                    this.contentForm.patchValue(formData);

                    if (this.content.imageThumbnail) this.patchImage();
                }),
                switchMap(() => this.localeService.$currentLocale),
                takeUntil(this.destroyed$)
            )
            .subscribe((locale: string) => {
                this.changeLanguage(locale);
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    changeLanguage(language: string) {
        this.languageSelector.value = language;
        this.locale = language as Locale;
        this.changeDetectorRef.detectChanges();
        if (this.editor) {
            this.editor?.save().then((data) => {
                this.contentForm.controls[`${this.previousLanguage}_content`].setValue(data);
                this.previousLanguage = language;
                this.editor.destroy();
                this.initializeEditor(language);
            });
        } else {
            this.previousLanguage = language;
            this.initializeEditor(language);
        }

        this.secondStepContent = this.contentForm.controls[`${language}_content`].value;
        this.changeDetectorRef.detectChanges();
    }

    compressAndUploadImage(file) {
        this.componentRef = this.viewContainerRef.createComponent(ImageCropperDialogComponent, {
            injector: this.injector,
        });
        const cropperData: CropperDialogData = {
            image: file,
            maintainAspectRatio: false,
            aspectRatioX: 1,
            aspectRatioY: 1,
            resizeToHeight: 0,
            resizeToWidth: 0,
        };
        this.componentRef.instance.data = cropperData;

        return new Promise((resolve, reject) => {
            const escapeListener = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this.componentRef.destroy();
                    document.removeEventListener('keydown', escapeListener);
                    reject({
                        success: 0,
                        file: {
                            url: null,
                        },
                    });
                }
            };

            document.addEventListener('keydown', escapeListener);

            this.componentRef.instance.croppedResult
                .pipe(
                    filter((croppedImage) => !!croppedImage),
                    switchMap((croppedImage: File) => {
                        this.componentRef.destroy();
                        document.removeEventListener('keydown', escapeListener);
                        return this.ng2ImgMaxService.compressImage(croppedImage, 1).pipe(
                            catchError((error) => {
                                handleCompressionErrors(error, this.translateService, this.snackBarService);
                                reject({
                                    success: 0,
                                    file: {
                                        url: null,
                                    },
                                });
                                return EMPTY;
                            })
                        );
                    }),
                    filter((compressedImage) => !!compressedImage),
                    switchMap((compressedImage: Blob) => {
                        return this.blobToBase64(compressedImage);
                    })
                )
                .subscribe({
                    next: (arrayBuffer: string) => {
                        const url = `data:${file.type};base64,${arrayBuffer}`;
                        resolve({
                            success: 1,
                            file: {
                                url: url,
                            },
                        });
                    },
                    error: () => {
                        reject({
                            success: 0,
                            file: {
                                url: null,
                            },
                        });
                    },
                });
        });
    }

    initializeEditor(locale: string) {
        const data = this.content ? this.content[`${locale}_content`] ?? null : null;
        let editorJSConfig = {
            readOnly: false,
            inlineToolbar: true,
            minHeight: 200,
            tools: {
                image: {
                    class: Image,
                    config: {
                        uploader: {
                            uploadByFile: (file) => this.compressAndUploadImage(file),
                        },
                    },
                },
                attaches: {
                    class: AttachesTool,
                    config: {
                        uploader: {
                            async uploadByFile(file: File) {
                                const buffer = await file.arrayBuffer();
                                var binary = '';
                                var bytes = new Uint8Array(buffer);
                                var len = bytes.byteLength;
                                for (var i = 0; i < len; i++) {
                                    binary += String.fromCharCode(bytes[i]);
                                }

                                let url = `data:${file.type};base64,${btoa(binary)}`.replace('"', '');

                                return {
                                    success: 1,
                                    file: {
                                        url: url,
                                    },
                                };
                            },
                        },
                    },
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+L',
                },
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: I18nDictionary[locale]?.quote.config.quotePlaceholder,
                        captionPlaceholder: I18nDictionary[locale]?.quote.config.captionPlaceholder,
                    },
                    shortcut: 'CMD+SHIFT+O',
                },
                warning: Warning,
                marker: {
                    class: Marker,
                    shortcut: 'CMD+SHIFT+M',
                },
                code: {
                    class: Code,
                    shortcut: 'CMD+SHIFT+C',
                },
                paragraph: {
                    class: Paragraph,
                    shortcut: 'ENTER',
                    inlineToolbar: true,
                    config: {
                        preserveBlank: true,
                    },
                },
                delimiter: Delimiter,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+C',
                },
                linkTool: Link,
                table: {
                    class: Table,
                    inlineToolbar: true,
                    shortcut: 'CMD+ALT+T',
                },
            },
            holder: this.editorElement.nativeElement,
        };

        this.editor = new EditorJS({
            readOnly: false,
            inlineToolbar: true,
            minHeight: 200,
            tools: {
                twoColumns: {
                    class: LayoutBlockTool,
                    config: {
                        EditorJS,
                        editorJSConfig,
                        enableLayoutEditing: false,
                        enableLayoutSaving: false,
                        initialData: {
                            itemContent: {
                                1: {
                                    blocks: [],
                                },
                                2: {
                                    blocks: [],
                                },
                            },
                            layout: {
                                type: 'container',
                                id: '',
                                className: '',
                                style: 'border: 1px solid rgba(48, 48, 48, 0.48); border-radius: 15px; display: flex; justify-content: space-around; padding: 5px; ',
                                children: [
                                    {
                                        type: 'item',
                                        id: '',
                                        className: '',
                                        style: 'border: 1px solid rgba(48, 48, 48, 0.48); border-radius: 15px; padding: 5px; width: 50%',
                                        itemContentId: '1',
                                    },
                                    {
                                        type: 'item',
                                        id: '',
                                        className: '',
                                        style: 'border: 1px solid rgba(48, 48, 48, 0.48); border-radius: 15px; padding: 5px; width: 50%',
                                        itemContentId: '2',
                                    },
                                ],
                            },
                        },
                    },
                    shortcut: 'CMD+2',
                    toolbox: {
                        icon: `
                    <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                      <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                      <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                    </svg>
                  `,
                        title: I18nDictionary[locale]?.quote.twoColumns.toolbox.title,
                    },
                },
                header: {
                    class: Header,
                    inlineToolbar: ['marker', 'link'],
                    config: {
                        placeholder: 'Header',
                    },
                    shortcut: 'CMD+SHIFT+H',
                },
                attaches: {
                    class: AttachesTool,
                    config: {
                        uploader: {
                            async uploadByFile(file: File) {
                                const buffer = await file.arrayBuffer();
                                var binary = '';
                                var bytes = new Uint8Array(buffer);
                                var len = bytes.byteLength;
                                for (var i = 0; i < len; i++) {
                                    binary += String.fromCharCode(bytes[i]);
                                }

                                let url = `data:${file.type};base64,${btoa(binary)}`.replace('"', '');

                                return {
                                    success: 1,
                                    file: {
                                        url: url,
                                    },
                                };
                            },
                        },
                    },
                },
                image: {
                    class: Image,
                    config: {
                        uploader: {
                            uploadByFile: (file) => this.compressAndUploadImage(file),
                        },
                    },
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+L',
                },
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: I18nDictionary[locale]?.quote.config.quotePlaceholder,
                        captionPlaceholder: I18nDictionary[locale]?.quote.config.captionPlaceholder,
                    },
                    shortcut: 'CMD+SHIFT+O',
                },
                warning: Warning,
                marker: {
                    class: Marker,
                    shortcut: 'CMD+SHIFT+M',
                },
                code: {
                    class: Code,
                    shortcut: 'CMD+SHIFT+C',
                },
                paragraph: {
                    class: Paragraph,
                    shortcut: 'ENTER',
                    inlineToolbar: true,
                    config: {
                        preserveBlank: true,
                    },
                },
                delimiter: Delimiter,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+C',
                },
                linkTool: Link,
                table: {
                    class: Table,
                    inlineToolbar: true,
                    shortcut: 'CMD+ALT+T',
                },
            },
            i18n: {
                messages: I18nDictionary[locale].i18n,
            },
            holder: this.editorElement.nativeElement,
            data: data,
        });
        this.editor.isReady.then(() => {
            if (this.contentForm.controls[`${locale}_content`].value)
                this.editor.render(this.contentForm.controls[`${locale}_content`].value);
        });
    }

    uploadImage(event) {
        const file: File = event.target.files[0];
        if (!file) {
            return;
        }

        this.componentRef = this.viewContainerRef.createComponent(ImageCropperDialogComponent, {
            injector: this.injector,
        });
        const cropperData: CropperDialogData = {
            image: file,
            maintainAspectRatio: true,
            aspectRatioX: 134,
            aspectRatioY: 75,
            resizeToHeight: 150,
            resizeToWidth: 268,
        };
        this.componentRef.instance.data = cropperData;

        const escapeListener = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                this.componentRef.destroy();
                document.removeEventListener('keydown', escapeListener);
            }
        };

        document.addEventListener('keydown', escapeListener);

        this.componentRef.instance.croppedResult
            .pipe(
                filter((croppedImage) => !!croppedImage),
                switchMap((croppedImage: File) => {
                    this.componentRef.destroy();
                    document.removeEventListener('keydown', escapeListener);
                    this.imageUpdated = true;
                    return this.ng2ImgMaxService.compressImage(croppedImage, 0.2).pipe(
                        catchError((error) => {
                            return handleCompressionErrors(error, this.translateService, this.snackBarService);
                        })
                    );
                }),
                filter((compressedImage) => !!compressedImage),
                switchMap((compressedImage: Blob) => {
                    return this.blobToBase64(compressedImage);
                })
            )
            .subscribe({
                next: (arrayBuffer: string) => {
                    this.content.imageThumbnail = getContentThumbnail(file, arrayBuffer);
                    this.contentForm.get('imageThumbnail').setValue(this.content.imageThumbnail);
                    createThumbnail(this.content.imageThumbnail.url);
                    this.thumbnailChanged = true;
                },
                error: (error) => {
                    console.error(error);
                },
            });
    }

    blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve((reader.result as string).split(',')[1]); // Extract the base64 part
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    patchImage() {
        const thumbnail = document.createElement('img');
        thumbnail.src = this.content.imageThumbnail.url;
        thumbnail.id = 'img';
        thumbnail.alt = 'Unknown';
        thumbnail.style.maxHeight = '100px';

        const container = document.getElementById('thumbnail-container');
        container.innerHTML = '';
        container.appendChild(thumbnail);
    }

    switchToOverviewTab() {
        if (this.contentForm.controls['de_title'].invalid) {
            this.snackBarService.showErrorAlert(
                this.translateService.instant('cabinet.news.wizard.messages.germanTitleMissing')
            );
            return;
        }

        if (this.contentForm.invalid) return;

        this.editor.save().then((savedData) => {
            this.contentForm.controls[`${this.languageSelector.value}_content`].setValue(savedData);
            this.secondStepContent = this.contentForm.controls[`${this.languageSelector.value}_content`].value;
            this.content = this.contentForm.getRawValue();
            this.goToStep(1);
        });
    }

    goBack() {
        this.goToStep(0);
    }

    getUserDate = (user) => {
        return [];
    };

    saveContent() {
        zip(
            this.userService.$currentUserData,
            this.userService.$company,
            this.userService.$pensionFund,
            this.userService.$foundation
        ).subscribe({
            next: ([user, company, pensionFund, foundation]) => {
                const content = mapFormDataToContent(
                    this.contentForm.getRawValue(),
                    user,
                    company,
                    pensionFund,
                    foundation,
                    this.thumbnailChanged
                );

                if (content?.id) {
                    this.updateContent(content);
                    return;
                }

                this.createContent(content);
            },
            error: (error) => console.log(error),
        });
    }

    updateContent(content) {
        this.cmService.editContent(this.content.id, content).subscribe({
            next: (data) => {
                this.snackBarService.showSuccessAlert(
                    this.translateService.instant('cabinet.news.wizard.messages.articleSuccessfullyEdited')
                );
                this.router.navigate(['/content-management']);
            },
            error: (err) => console.error(err),
        });
    }

    createContent(content) {
        this.cmService.createContent(content).subscribe({
            next: (data) => {
                this.snackBarService.showSuccessAlert(
                    this.translateService.instant('cabinet.news.wizard.messages.articleSuccessfullyCreated')
                );
                this.router.navigate(['/content-management']);
            },
            error: (err) => console.error(err),
        });
    }

    deleteThumbnail() {
        this.thumbnailChanged = true;
        this.contentForm.get('imageThumbnail').setValue(null);
        const container = document.getElementById('thumbnail-container');
        container.removeChild(container.firstChild);
    }
}

const I18nDictionary = {
    en: {
        i18n: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': 'Click to tune',
                        'or drag to move': 'or drag to move',
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': 'Convert to',
                    },
                },
                toolbar: {
                    toolbox: {
                        Add: 'Add',
                    },
                },
            },
            toolNames: {
                Text: 'Text',
                Heading: 'Heading',
                List: 'List',
                Warning: 'Warning',
                Checklist: 'Checklist',
                Quote: 'Quote',
                Code: 'Code',
                Delimiter: 'Delimiter',
                Table: 'Table',
                Link: 'Link',
                Marker: 'Marker',
                Bold: 'Bold',
                Italic: 'Italic',
                InlineCode: 'InlineCode',
                Attachment: 'Attachment',
                Image: 'Image',
            },
            tools: {
                warning: {
                    Title: 'Title',
                    Message: 'Message',
                },
                link: {
                    'Add a link': 'Add a link',
                },
                stub: {
                    'The block can not be displayed correctly.': 'The block can not be displayed correctly.',
                },
            },
            blockTunes: {
                delete: {
                    Delete: 'Delete',
                },
                moveUp: {
                    'Move up': 'Move up',
                },
                moveDown: {
                    'Move down': 'Move down',
                },
            },
        },
        quote: {
            config: {
                quotePlaceholder: 'Enter a quote',
                captionPlaceholder: "Quote's author",
            },
            twoColumns: {
                toolbox: {
                    title: '2 Columns',
                },
            },
        },
    },
    de: {
        i18n: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': 'Drücken Sie zum Konfigurieren',
                        'or drag to move': 'oder ziehen zum Verschieben',
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': 'umwandeln in',
                    },
                },
                toolbar: {
                    toolbox: {
                        Add: 'Hinzufügen',
                    },
                },
            },
            toolNames: {
                Text: 'Absatz',
                Heading: 'Überschrift',
                List: 'Liste',
                Warning: 'Warnung',
                Checklist: 'Checkliste',
                Quote: 'Zitat',
                Code: 'Code',
                Delimiter: 'Begrenzungszeichen',
                Table: 'Tabelle',
                Link: 'Link',
                Marker: 'Markierung',
                Bold: 'Kühn',
                Italic: 'Kursiv',
                InlineCode: 'InlineCode',
                Attachment: 'Anlage',
                Image: 'Bild',
            },
            tools: {
                warning: {
                    Title: 'Titel',
                    Message: 'Nachricht',
                },
                link: {
                    'Add a link': 'Einen Link hinzufügen',
                },
                stub: {
                    'The block can not be displayed correctly.': 'Der Block kann nicht korrekt angezeigt werden.',
                },
                table: {
                    'Add row above': 'Zeile oben hinzufügen',
                    'Add row below': 'Zeile unten hinzufügen',
                    'Delete row': 'Zeile löschen',
                    'With headings': 'Mit Kopfzeilen',
                    'Without headings': 'Ohne Kopfzeilen',
                },
            },
            blockTunes: {
                delete: {
                    Delete: 'Löschen',
                    'Click to delete': 'Löschen bestätigen',
                },
                moveUp: {
                    'Move up': 'Nach oben',
                },
                moveDown: {
                    'Move down': 'Nach unten gehen',
                },
                'Without headings': 'TEST 2',
            },
        },
        quote: {
            config: {
                quotePlaceholder: 'Angebot eingeben',
                captionPlaceholder: 'Autor des Zitats',
            },
            twoColumns: {
                toolbox: {
                    title: '2 Spalten',
                },
            },
            table: {
                toolbox: {
                    'Add row above': 'TEST',
                },
            },
        },
    },
    fr: {
        i18n: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': 'Cliquez pour régler',
                        'or drag to move': 'ou faites glisser pour déplacer',
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': 'Convertir en',
                    },
                },
                toolbar: {
                    toolbox: {
                        Add: 'Ajouter',
                    },
                },
            },
            toolNames: {
                Text: 'Texte',
                Heading: 'Titre',
                List: 'Liste',
                Warning: 'Avertissement',
                Checklist: 'Liste de contrôle',
                Quote: 'Citation',
                Code: 'Code',
                Delimiter: 'Délimiteur',
                Table: 'Table',
                Link: 'Lien',
                Marker: 'Marqueur',
                Bold: 'Gras',
                Italic: 'Italique',
                InlineCode: 'Code en ligne',
                Attachment: 'Pièce jointe',
                Image: 'Image',
            },
            tools: {
                warning: {
                    Title: 'Titre',
                    Message: 'Message',
                },
                link: {
                    'Add a link': 'Ajouter un lien',
                },
                stub: {
                    'The block can not be displayed correctly.': 'Le bloc ne peut pas être affiché correctement.',
                },
                table: {
                    'Add row above': 'Ajouter la ligne supérieure',
                    'Add row below': 'Ajouter une ligne en dessous',
                    'Delete row': 'Supprimer la ligne',
                    'With headings': 'Avec titres',
                    'Without headings': 'Sans titres',
                },
            },
            blockTunes: {
                delete: {
                    Delete: 'Supprimer',
                    'Click to delete': 'Confirmer la suppression',
                },
                moveUp: {
                    'Move up': 'Monter',
                },
                moveDown: {
                    'Move down': 'Descendre',
                },
            },
        },
        quote: {
            config: {
                quotePlaceholder: 'Entrez une citation',
                captionPlaceholder: 'Auteur de la citation',
            },
            twoColumns: {
                toolbox: {
                    title: '2 Colonnes',
                },
            },
        },
    },
    it: {
        i18n: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': 'Clicca per regolare',
                        'or drag to move': 'o trascina per spostare',
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': 'Converti in',
                    },
                },
                toolbar: {
                    toolbox: {
                        Add: 'Aggiungi',
                    },
                },
            },
            toolNames: {
                Text: 'Testo',
                Heading: 'Titolo',
                List: 'Elenco',
                Warning: 'Avviso',
                Checklist: 'Lista di controllo',
                Quote: 'Citazione',
                Code: 'Codice',
                Delimiter: 'Delimitatore',
                Table: 'Tabella',
                Link: 'Collegamento',
                Marker: 'Marcatore',
                Bold: 'Grassetto',
                Italic: 'Corsivo',
                InlineCode: 'Codice inline',
                Attachment: 'Allegato',
                Image: 'Immagine',
            },
            tools: {
                warning: {
                    Title: 'Titolo',
                    Message: 'Messaggio',
                },
                link: {
                    'Add a link': 'Aggiungi un collegamento',
                },
                stub: {
                    'The block can not be displayed correctly.': 'Il blocco non può essere visualizzato correttamente.',
                },
                table: {
                    'Add row above': 'Aggiungi riga superiore',
                    'Add row below': 'Aggiungi riga in basso',
                    'Delete row': 'Elimina riga',
                    'With headings': 'Con intestazioni',
                    'Without headings': 'Senza titoli',
                },
            },
            blockTunes: {
                delete: {
                    Delete: 'Elimina',
                    'Click to delete': "Confermare l'eliminazione",
                },
                moveUp: {
                    'Move up': 'Sposta su',
                },
                moveDown: {
                    'Move down': 'Sposta giù',
                },
            },
        },
        quote: {
            config: {
                quotePlaceholder: 'Inserisci una citazione',
                captionPlaceholder: 'Autore della citazione',
            },
            twoColumns: {
                toolbox: {
                    title: '2 Colonne',
                },
            },
        },
    },
};
