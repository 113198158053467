<div class="vote-meter" [ngClass]="{ light: (1 | colourTheme) === false, active: isActive }">
  <div class="vote-meter__main" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="vote-meter__colors">
      @if (statusColor == 'yes' && (1 | colourTheme) === false) {
        <img
          src="../../../../assets/img/vote/green.svg"
          />
      }
      @if (statusColor == 'no' && (1 | colourTheme) === false) {
        <img src="../../../../assets/img/vote/red.svg" />
      }
      @if (statusColor == 'neutral' && (1 | colourTheme) === false) {
        <img
          src="../../../../assets/img/vote/yellow.svg"
          />
      }
      @if (statusColor == 'yes' && (1 | colourTheme) === true) {
        <img
          src="../../../../assets/img/vote/green-dark.svg"
          />
      }
      @if (statusColor == 'no' && (1 | colourTheme) === true) {
        <img
          src="../../../../assets/img/vote/red-dark.svg"
          />
      }
      @if (statusColor == 'neutral' && (1 | colourTheme) === true) {
        <img
          src="../../../../assets/img/vote/yellow-dark.svg"
          />
      }
    </div>
    <div class="vote-meter__numbers">
      @if ((1 | colourTheme) === false) {
        <img src="../../../../assets/img/vote/numbers.svg" />
      }
      @if ((1 | colourTheme) === true) {
        <img src="../../../../assets/img/vote/numbers-dark.svg" />
      }
    </div>
    <div class="vote-meter__arrow">
      @if ((1 | colourTheme) === false) {
        <img
                [ngStyle]="{
                    transform: 'rotate(' + degree + 'deg)'
                }"
          src="../../../../assets/img/vote/arrow-white.svg"
          />
      }
      @if ((1 | colourTheme) === true) {
        <img
                [ngStyle]="{
                    transform: 'rotate(' + degree + 'deg)'
                }"
          src="../../../../assets/img/vote/arrow-dark.svg"
          />
      }
    </div>
    <div
      class="vote-meter__btn"
            [ngClass]="{
                yes: statusColor === 'yes',
                no: statusColor === 'no',
                neutral: statusColor === 'neutral',
                active: isActive,
                light: (1 | colourTheme) === false
            }"
      >
      {{ statusColor }}
    </div>
    <div class="vote-meter__percents" [ngClass]="{ light: (1 | colourTheme) === false, active: isActive }">
      {{ percent }}<span class="percent">%</span>
    </div>
  </div>
</div>
