import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(public http: HttpClient) {}

    getUrl(): string {
        return `${environment.clanDataApiUrl}/${this.getRoute()}`;
    }

    getRoute(): string {
        return '';
    }
}
