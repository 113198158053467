import { Inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Locale } from 'libs/shared/src/lib/models/types/locale';

@Injectable()
export class LocaleService {
    private currentLang: BehaviorSubject<Locale> = new BehaviorSubject<Locale>(environment.localeData.defaultLocale);
    constructor(
        private translateService: TranslateService,
        @Inject(CookieService) private cookieService: CookieService
    ) {}

    get $currentLocale(): Observable<Locale> {
        return this.currentLang.asObservable();
    }

    get $langChange(): Observable<LangChangeEvent> {
        return this.translateService.onLangChange.asObservable();
    }

    get localeList(): Locale[] {
        return environment.localeData.locales;
    }

    private static setBodyClassName(locale: Locale): void {
        document.body.classList.add(locale);
    }

    private static removeLastLangBodyClassName(prevLocale: Locale): void {
        document.body.classList.remove(prevLocale);
    }

    private langChangeHandler($changeLangEvent: Observable<LangChangeEvent>): void {
        $changeLangEvent.subscribe((changeLangEvent) => {
            LocaleService.removeLastLangBodyClassName(this.currentLang.value);
            this.currentLang.next(changeLangEvent.lang as Locale);
            this.cookieService.set('lang', String(this.currentLang.value), 365, '/');
            LocaleService.setBodyClassName(this.currentLang.value);
        });
    }
    getTranslate(translateField: string) {
        return this.translateService.get(translateField);
    }

    changeLocale(locale: Locale): void {
        this.translateService.use(locale);
    }

    initTranslate(locale: Locale): void {
        this.currentLang.next(locale);
        this.translateService.use(locale);
        LocaleService.setBodyClassName(this.currentLang.value);

        this.langChangeHandler(this.$langChange);
    }
}
