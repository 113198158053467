@if (stepElements.length) {
  <div class="my-purchase-main__stepper">
    @for (element of stepElements; track element) {
      <div class="my-purchase-main__stepper-element">
        <div
          class="my-purchase-main__stepper-point stepper"
            [ngClass]="{
                first: element.id === 1,
                last: element.id === stepElements.length,
                active: element.active,
                ready: element.passed
            }"
          >
          {{ !element.active && !element.passed ? element.id : '' }}
          @if (element.passed) {
            <svg
              class="primary-fill"
              width="32"
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              >
              <rect class="primary-stroke primary-fill" x="0" y="0" width="32" height="32" rx="14.5" fill="#4C68A6" />
              <path
                d="M25 10.5001L13 22.5001L7.5 17.0001L8.91 15.5901L13 19.6701L23.59 9.09009L25 10.5001Z"
                fill="#F2F2F2"
                />
              <rect
                class="primary-stroke"
                x="1"
                y="1"
                width="30"
                height="29"
                rx="14.5"
                stroke="#4C68A6"
                stroke-width="2"
                />
            </svg>
          }
          @if (element.active) {
            <svg class="primary" width="8" viewBox="0 0 8 32" xmlns="http://www.w3.org/2000/svg">
              <circle class="primary-stroke primary-fill" cx="4" cy="16" r="4" fill="#4C68A6" />
            </svg>
          }
        </div>
        <div
          class="my-purchase-main__stepper-element-name"
            [ngClass]="{
                active: element.active,
                ready: element.passed,
                light: (1 | colourTheme) === false
            }"
          >
          {{ element.name | translate }}
        </div>
      </div>
    }
  </div>
}

<hr class="form-separator" />

<div class="lang-container">
  <div class="inputItem">
    <mat-label class="fieldLabel">{{ 'cabinet.news.wizard.prepareNewsStep.languages' | translate }}</mat-label>
    <mat-form-field appearance="outline" class="mat-form-field" [floatLabel]="'always'">
      <mat-select
        [hideSingleSelectionIndicator]="true"
        #languageSelector
        (selectionChange)="changeLanguage($event.value)"
        >
        @for (language of languages; track language) {
          <mat-option [value]="language">
            {{ language | uppercase }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
<hr class="form-separator" />

<div [style.display]="activeStep().id === 1 ? 'block' : 'none'">
  <form [formGroup]="contentForm" (ngSubmit)="switchToOverviewTab()" class="container">
    @if (languageSelector.value === 'de') {
      <div class="inputItem title-field">
        <mat-label class="fieldLabel"
          >{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }} ({{
          languageSelector.value | uppercase
          }})</mat-label
          >
          <mat-form-field appearance="outline" class="mat-form-field" [floatLabel]="'always'">
            <mat-label>{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }}</mat-label>
            <input matInput formControlName="de_title" type="text" />
          </mat-form-field>
        </div>
      }

      @if (languageSelector.value === 'en') {
        <div class="inputItem title-field">
          <mat-label class="fieldLabel"
            >{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }} ({{
            languageSelector.value | uppercase
            }})</mat-label
            >
            <mat-form-field appearance="outline" class="mat-form-field" [floatLabel]="'always'">
              <mat-label>{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }}</mat-label>
              <input matInput formControlName="en_title" type="text" />
            </mat-form-field>
          </div>
        }

        @if (languageSelector.value === 'fr') {
          <div class="inputItem title-field">
            <mat-label class="fieldLabel"
              >{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }} ({{
              languageSelector.value | uppercase
              }})</mat-label
              >
              <mat-form-field appearance="outline" class="mat-form-field" [floatLabel]="'always'">
                <mat-label>{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }}</mat-label>
                <input matInput formControlName="fr_title" type="text" />
              </mat-form-field>
            </div>
          }

          @if (languageSelector.value === 'it') {
            <div class="inputItem title-field">
              <mat-label class="fieldLabel"
                >{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }} ({{
                languageSelector.value | uppercase
                }})</mat-label
                >
                <mat-form-field appearance="outline" class="mat-form-field" [floatLabel]="'always'">
                  <mat-label>{{ 'cabinet.news.wizard.prepareNewsStep.titleLabel' | translate }}</mat-label>
                  <input matInput formControlName="it_title" type="text" />
                </mat-form-field>
              </div>
            }

            <div class="inputItem">
              <mat-label class="fieldLabel">{{ 'cm.render-engine' | translate }}</mat-label>
              <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
                <mat-label>{{ 'cm.render-engine' | translate }}</mat-label>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="renderEngine">
                  @for (engine of renderEngines; track engine) {
                    <mat-option [value]="engine">{{ engine.value }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="inputItem">
              <mat-label class="fieldLabel">{{ 'cm.content-type' | translate }}</mat-label>
              <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
                <mat-label>{{ 'cm.content-type' | translate }}</mat-label>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="contentType">
                  @for (contentType of contentTypes; track contentType) {
                    <mat-option [value]="contentType">{{
                      'cm.content-types.' + contentType | translate | uppercase
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            @if (contentForm.controls.contentType.value == 'NEWS') {
              <div class="inputItem thumbnail-field">
                <mat-label class="fieldLabel">{{ 'cm.thumbnail' | translate }}</mat-label>
                @if (!contentForm.controls.imageThumbnail.value) {
                  <label class="btn">
                    <input style="display: none" type="file" accept="image/*" (change)="uploadImage($event)" />
                    {{ 'cabinet.news.uploadImage' | translate | uppercase }}
                  </label>
                }
                <div class="thumbnail-wrapper">
                  <div id="thumbnail-container"></div>
                  @if (contentForm.controls.imageThumbnail.value) {
                    <div (click)="deleteThumbnail()" class="icon">
                      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M4 7H20"
                            stroke="var(--primary)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10"
                            stroke="var(--primary)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                            stroke="var(--primary)"
                            stroke-width=" 2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  }
                </div>
              </div>
              <div class="inputItem">
                <mat-label class="fieldLabel">{{ 'cm.target-group' | translate }}</mat-label>
                <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
                  <mat-label>{{ 'cm.target-group' | translate }}</mat-label>
                  <mat-select [hideSingleSelectionIndicator]="true" formControlName="targetGroup">
                    @for (targetGroup of targetGroups; track targetGroup) {
                      <mat-option [value]="targetGroup">{{
                        targetGroup
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            }

            <div class="inputItem">
              <mat-label class="fieldLabel">{{ 'cm.status' | translate }}</mat-label>
              <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
                <mat-label>{{ 'cm.status' | translate }}</mat-label>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="status">
                  @for (status of statuses; track status) {
                    <mat-option [value]="status">{{
                      'cm.statuses.' + status | translate | uppercase
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="inputItem">
              <mat-label class="fieldLabel">{{ 'cm.menu-location' | translate }}</mat-label>
              <mat-form-field class="filter-element" appearance="outline" [floatLabel]="'always'">
                <mat-label>{{ 'cm.menu-location' | translate }}</mat-label>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="location">
                  @for (location of documentLocations; track location) {
                    <mat-option [value]="location">{{
                      location.name
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="inputItem editor-wrapper">
              <mat-label class="fieldLabel marginTop alignLeft"
                >{{ 'cm.content' | translate }} ({{ languageSelector.value | uppercase }})</mat-label
                >
                <div #editor class="editor-js"></div>
              </div>

              <div class="inputItem">
                <button type="submit" class="button btn-rounded wider submitBtn">
                  {{ 'cabinet.news.wizard.prepareNewsStep.overviewBtn' | translate }}
                </button>
              </div>
            </form>
          </div>

          <div [style.display]="activeStep().id === 2 ? 'block' : 'none'">
            <form [formGroup]="contentForm" (ngSubmit)="saveContent()">
              <h5 class="title">{{ 'cabinet.news.wizard.publishNewsStep.preview' | translate }}</h5>

              @if (activeStep().id === 2) {
                <div class="inputItem content-view">
                  <app-content-view [content]="secondStepContent"></app-content-view>
                </div>
              }

              <div class="button-container">
                <button type="button" class="button btn-rounded wider submitBtn" (click)="goBack()">
                  {{ 'common.back' | translate }}
                </button>

                <button type="submit" class="button btn-rounded wider submitBtn">
                  {{ 'cabinet.news.wizard.publishNewsStep.publishBtn' | translate }}
                </button>
              </div>
            </form>
          </div>
