import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColourThemePipe } from '../../pipes/colour-theme.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-type-chip',
    templateUrl: './type-chip.component.html',
    styleUrls: ['./type-chip.component.scss'],
    standalone: true,
    imports: [NgClass, ColourThemePipe],
})
export class TypeChipComponent implements OnInit {
    @Input() type: Chips = 'all';
    @Input() clickable = false;
    @Input() selected = false;
    @Input() text: string;
    @Output() selectChip: EventEmitter<Chips> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    select() {
        if (this.clickable) {
            this.selectChip.emit(this.type);
        }
    }
}

export type Chips = 'all' | 'vote' | 'notification' | 'verification';
