import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    cookieTokenName = 'swarm-auth-token';
    cookieRefreshTokenName = 'swarm-refresh-token';

    constructor(
        private authService: AuthenticationService,
        private _translateService: TranslateService,
        private _router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<object>> {
        let authReq = req;
        const token = this.authService.authToken;
        if (token != null && !this.isRefreshing) {
            authReq = this.addTokenHeader(req, token);
        } else if (this.isRefreshing) {
            authReq = req.clone({
                setHeaders: {},
            });
        }

        return next.handle(authReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && !authReq.url.includes('/auth') && error.status === 401) {
                    return this.handle401Error(authReq, next);
                }

                if (
                    error.status >= 400 &&
                    error.status <= 410 &&
                    !authReq.url.includes('/auth') &&
                    !authReq.url.includes('logout')
                ) {
                    if (!this.isRefreshing) {
                        this.authService.logout().subscribe(() => {
                            this._router.navigate(['/auth']);
                        });
                    }
                }

                return throwError(() => error);
            })
        );
    }

    translateErrorCode(code: string) {
        const key = `auth.errorCodes.${code}`;
        const translation = this._translateService.instant(key);

        if (translation.includes(key)) {
            return this._translateService.instant(`auth.errorCodes.0`);
        }
        return translation;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const token = this.authService.authRefreshToken;
            if (token) {
                return this.authService.refreshTokenReq().pipe(
                    switchMap((res: any) => {
                        this.isRefreshing = false;
                        sessionStorage.removeItem(this.cookieTokenName);
                        sessionStorage.setItem(this.cookieTokenName, res['access_token']);

                        this.refreshTokenSubject.next(res['access_token']);

                        return next.handle(this.addTokenHeader(request, res['access_token']));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        return throwError(() => new Error(err.error));
                    })
                );
            }
        }

        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
        });
    }
}
