import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AkTransactionService extends ApiService {
    override getRoute(): string {
        return 'ak-transactions';
    }

    getTransactions(page: any, sort: any, filter: any) {
        let paramsInfo: any = {};
        paramsInfo.pageNumber = `page=${page?.pageIndex !== undefined ? page.pageIndex : 0}`;
        paramsInfo.pageSize = `size=${page?.pageSize !== undefined ? page.pageSize : 10}`;
        paramsInfo.sortColumn = `sort=${sort?.sortColumn !== undefined ? sort.sortColumn : 'id'}`;
        paramsInfo.sortDirection = `sortDirection=${sort?.sortDirection !== undefined ? sort.sortDirection : 'asc'}`;

        if (filter?.bookingType !== undefined && filter?.bookingType !== null) {
            paramsInfo.bookingType = `bookingTypeId=${filter.bookingType}`;
        }

        if (filter?.personId !== undefined && filter?.personId !== null) {
            paramsInfo.personId = `personId=${filter.personId}`;
        }

        if (filter?.dateFrom !== undefined && filter?.dateFrom !== null) {
            paramsInfo.dateFrom = `dateFrom=${filter.dateFrom}`;
        }

        if (filter?.dateTo !== undefined && filter?.dateTo !== null) {
            paramsInfo.dateTo = `dateTo=${filter.dateTo}`;
        }

        if (filter?.amountFrom !== undefined && filter?.amountFrom !== null) {
            paramsInfo.valueFrom = `valueFrom=${filter.amountFrom}`;
        }

        if (filter?.amountTo !== undefined && filter?.amountTo !== null) {
            paramsInfo.valueTo = `valueTo=${filter.amountTo}`;
        }

        if (filter?.remark !== undefined && filter?.remark !== null) {
            paramsInfo.comment = `comment=${filter.remark}`;
        }

        if (filter?.accountType !== undefined && filter?.accountType !== null) {
            paramsInfo.accountType = `accountType=${filter.accountType}`;
        }

        if (filter?.bookingId !== undefined && filter?.bookingId !== null && filter?.bookingId !== '') {
            paramsInfo.bookingId = `pvClanBookingId=${filter.bookingId}`;
        }

        if (filter?.vsnum !== undefined && filter?.vsnum !== null) {
            paramsInfo.vsnum = `vsnum=${filter.vsnum}`;
        }
        if (filter?.createdDateFrom !== undefined && filter?.createdDateFrom !== null) {
            paramsInfo.createdDateFrom = `createdDateFrom=${filter.createdDateFrom}`;
        }
        if (filter?.createdDateTo !== undefined && filter?.createdDateTo !== null) {
            paramsInfo.createdDateTo = `createdDateTo=${filter.createdDateTo}`;
        }
        if (filter?.bookingTypeId !== undefined && filter?.bookingTypeId !== null) {
            paramsInfo.bookingTypeId = `bookingTypeId=${filter.bookingTypeId}`;
        }

        let url = this.getUrl() + '?';
        // Iterate through the properties in paramsInfo and add them to the URL
        for (const key of Object.keys(paramsInfo)) {
            url += paramsInfo[key] + '&';
        }

        // Remove the trailing '&' if it exists
        if (url.endsWith('&')) {
            url = url.slice(0, -1);
        }

        return this.http.get<any>(url);
    }

    getByTransactionId(transactionId: number) {
        return this.http.get<any>(`${this.getUrl()}/transactions/${transactionId}`);
    }
}
