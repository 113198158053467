<div class="Login-Title-Box">
  <div class="swarmLogo" [ngClass]="{ light: 1 }"></div>
</div>

@if (isTokenValid) {
  <div class="mainBox">
    <div class="linkBox" [ngClass]="{ light: 1 }">
      <a><p>{{ 'auth.activateText' | translate }}</p></a>
    </div>
    <a routerLink="/auth" (click)="activateEmail()"><button
      class="submit-button"
      [ngClass]="{light: 1}"
      type="submit"
      >
      <span>{{ 'auth.verify' | translate }}</span>
    </button></a>
  </div>
}
@if (!isTokenValid) {
  <div class="mainBox">
    <p class="linkInvalidText" [ngClass]="{ light: 1 }"> {{ 'auth.emailVerificationInvalidText' | translate }}</p>
    <a routerLink="/auth" (click)="activateEmail()"><button
      class="submit-button"
      [ngClass]="{light: 1}"
      type="submit" >
      <span>{{ 'auth.login' | translate }}</span>
    </button></a>
  </div>
}
