<div class="Login-Title-Box">
  <div class="swarmLogo" [ngClass]="{ light: 1}"></div>
</div>

<div class="mainBox">
  <!-- show if email link expired -->
  @if (!isTokenValid && !resetSuccess) {
    <div>
      <div class="oldPWResetLink" [ngClass]="{ light: 1}">{{ 'auth.resetEmail.pwResetLinkExpired' | translate }}</div>
      <a routerLink="/resetPassword"><button
        class="submit-button"
        [ngClass]="{light: 1}">
        <span>
          {{ 'auth.resetEmail.header' | translate }}
        </span>
      </button>
    </a>
  </div>
}
@if (resetSuccess) {
  <div>
    <p class="resetSuccessText" [ngClass]="{ light: 1}"> {{ 'auth.passwordResetSuccessText' | translate }}</p>
    <a routerLink="/auth">
      <button
        class="submit-button"
        [ngClass]="{light: 1}">
        <span>
          {{ 'auth.loginButton' | translate }}
        </span>
      </button>
    </a>
  </div>
}

<!-- show if email link current/valid -->
@if (isTokenValid && !resetSuccess) {
  <form
    [formGroup]="pwResetForm"
    (ngSubmit)="SubmitPwReset()"
    >
    <div class="infoText">
      <p>{{ 'auth.setNewEmail.setNewEmailInfoText' | translate}}</p>
      <p>{{ 'auth.setNewEmail.setNewEmailInfoTextPWGuidline' | translate}}</p>
    </div>
    <div class="form-group" id="passwordBox" >
      <div
        class="input-field"
        [ngClass]="{ light: 1,'is-invalid': submitted &&  f['password'].errors, active: pwReset.password.active }"
        >
        <label for="password">{{ 'auth.password' | translate}}</label>
        <input
          (focus)="inputFocusHandler('password')"
          (blur)="inputBlurHandler('password')"
          [type]="pwReset.password.type"
          (input)="inputFocusHandler('password')"
          (keyup)="checkIfSamePW($event, '1')"
          autocomplete="off"
          id="password"
          formControlName="password"
          class="form-control"
          />
          <div (click)="togglePasswordFieldInputType()" class="toggle-type">
            @if (pwReset.password.type === 'text') {
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                  d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                  fill="#61646E"
                  />
                </svg>
              }
              @if (pwReset.password.type === 'password') {
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                    d="M12 6.49995C15.6254 6.49995 18.8757 8.449 20.5954 11.5681C20.7437 11.8371 20.7447 12.1631 20.597 12.4324C20.0282 13.4695 19.281 14.3721 18.41 15.12L19.82 16.53C21.0166 15.4711 21.9983 14.1825 22.6885 12.7216C22.9043 12.2648 22.9037 11.7353 22.6876 11.2786C20.792 7.27313 16.7215 4.49995 12 4.49995C10.73 4.49995 9.51 4.69995 8.36 5.06995L10.01 6.71995C10.66 6.58995 11.32 6.49995 12 6.49995ZM10.93 7.63995L13 9.70995C13.57 9.95995 14.03 10.42 14.28 10.99L16.35 13.06C16.43 12.72 16.49 12.36 16.49 11.99C16.5 9.50995 14.48 7.49995 12 7.49995C11.63 7.49995 11.28 7.54995 10.93 7.63995ZM2.71241 3.66256C2.32424 4.05348 2.32506 4.68501 2.71461 5.07456L4.69 7.04995C3.26232 8.17107 2.09548 9.6144 1.31041 11.2792C1.0952 11.7355 1.09614 12.2642 1.31192 12.7203C3.20724 16.7263 7.27809 19.5 12 19.5C13.52 19.5 14.98 19.21 16.32 18.68L19.0349 21.3949C19.4243 21.7843 20.0557 21.7843 20.4451 21.3949C20.8344 21.0055 20.8345 20.3743 20.4453 19.9849L4.12961 3.65996C3.73814 3.26827 3.1026 3.2696 2.71241 3.66256ZM9.51 11.87L12.12 14.48C12.08 14.49 12.04 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 11.95 9.51 11.92 9.51 11.87ZM6.11 8.46995L7.86 10.22C7.63 10.77 7.5 11.37 7.5 12C7.5 14.48 9.52 16.5 12 16.5C12.63 16.5 13.23 16.37 13.77 16.14L14.75 17.12C13.87 17.36 12.95 17.5 12 17.5C8.37556 17.5 5.12609 15.552 3.40603 12.4344C3.25683 12.164 3.25661 11.836 3.40642 11.5659C4.09221 10.3295 5.02467 9.29516 6.11 8.46995Z"
                    fill="#61646E"
                    />
                  </svg>
                }
              </div>
            </div>
            <!-- <div *ngIf="f.password.errors" class="error-text">
            <span *ngIf="f.password.errors.required" class="error error--email">
              {{ 'auth.errors.password.required' | translate }}
            </span>
          </div> -->
        </div>
        @if (loginErrors) {
          <div class="errorTextBox"><span class="error-text">{{ 'auth.loginErrors' | translate }}</span></div>
        }
        <div class="form-group" >
          <div
            class="input-field"
            [ngClass]="{ light: 1, 'is-invalid': submitted &&  f['password2'].errors, active: pwReset.password2.active }"
            >
            <label for="password2">
              {{ 'auth.password' | translate }}
            </label>
            <input
              (focus)="inputFocusHandler('password2')"
              (blur)="inputBlurHandler('password2')"
              [type]="pwReset.password2.type"
              (input)="inputFocusHandler('password2')"
              (keyup)="checkIfSamePW($event, '2')"
              (keyup)="activatedHelpText()"
              autocomplete="off"
              id="password2"
              formControlName="password2"
              class="form-control"/>
              <div (click)="togglePasswordFieldInputType()" class="toggle-type">
                @if (pwReset.password.type === 'text') {
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                      d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                      fill="#61646E"
                      />
                    </svg>
                  }
                  @if (pwReset.password.type === 'password') {
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      >
                      <path
                        d="M12 6.49995C15.6254 6.49995 18.8757 8.449 20.5954 11.5681C20.7437 11.8371 20.7447 12.1631 20.597 12.4324C20.0282 13.4695 19.281 14.3721 18.41 15.12L19.82 16.53C21.0166 15.4711 21.9983 14.1825 22.6885 12.7216C22.9043 12.2648 22.9037 11.7353 22.6876 11.2786C20.792 7.27313 16.7215 4.49995 12 4.49995C10.73 4.49995 9.51 4.69995 8.36 5.06995L10.01 6.71995C10.66 6.58995 11.32 6.49995 12 6.49995ZM10.93 7.63995L13 9.70995C13.57 9.95995 14.03 10.42 14.28 10.99L16.35 13.06C16.43 12.72 16.49 12.36 16.49 11.99C16.5 9.50995 14.48 7.49995 12 7.49995C11.63 7.49995 11.28 7.54995 10.93 7.63995ZM2.71241 3.66256C2.32424 4.05348 2.32506 4.68501 2.71461 5.07456L4.69 7.04995C3.26232 8.17107 2.09548 9.6144 1.31041 11.2792C1.0952 11.7355 1.09614 12.2642 1.31192 12.7203C3.20724 16.7263 7.27809 19.5 12 19.5C13.52 19.5 14.98 19.21 16.32 18.68L19.0349 21.3949C19.4243 21.7843 20.0557 21.7843 20.4451 21.3949C20.8344 21.0055 20.8345 20.3743 20.4453 19.9849L4.12961 3.65996C3.73814 3.26827 3.1026 3.2696 2.71241 3.66256ZM9.51 11.87L12.12 14.48C12.08 14.49 12.04 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 11.95 9.51 11.92 9.51 11.87ZM6.11 8.46995L7.86 10.22C7.63 10.77 7.5 11.37 7.5 12C7.5 14.48 9.52 16.5 12 16.5C12.63 16.5 13.23 16.37 13.77 16.14L14.75 17.12C13.87 17.36 12.95 17.5 12 17.5C8.37556 17.5 5.12609 15.552 3.40603 12.4344C3.25683 12.164 3.25661 11.836 3.40642 11.5659C4.09221 10.3295 5.02467 9.29516 6.11 8.46995Z"
                        fill="#61646E"
                        />
                      </svg>
                    }
                  </div>
                </div>
                @if (f['password2'].errors) {
                  <div class="error-text">
                    @if (f['password2'].errors['email']) {
                      <span class="error error--email">
                        {{ 'auth.errors.email.incorrect' | translate }}
                      </span>
                    }
                    <!-- <span *ngIf="f.password2.errors.required" class="error error--email">
                    {{ 'auth.errors.email.required' | translate }}
                  </span> -->
                </div>
              }
            </div>
            @if (pwToShort) {
              <div class="pwNotTheSameText" [ngClass]="{ light: 1}" >{{ 'auth.setNewEmail.passwordToShort' | translate}}</div>
            }
            @if (pwNotTheSame && pwFieldEmpty && isHelpTextActive) {
              <div class="pwTheSameText" [ngClass]="{ light: 1}" >{{ 'auth.passwordValid' | translate}}</div>
            }
            @if (!pwNotTheSame && isHelpTextActive) {
              <div  class="pwNotTheSameText" [ngClass]="{ light: 1}" >{{ 'auth.passwordInvalid' | translate}}</div>
            }
            <button
              [disabled]="pwResetForm.invalid"
              [disabled]="!pwNotTheSame"
              class="submit-button"
              [ngClass]="{light: 1}"
              type="submit"
              >
              <span>
                {{ 'auth.passwordResetTitle' | translate }}
              </span>
            </button>
            <div class="divideLine" [ngClass]="{ light: 1}"></div>
          </form>
        }



      </div>
