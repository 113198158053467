import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
    private activeRequests = 0;
    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();

    setLoading(loading: boolean): void {
        this.activeRequests += loading ? 1 : -1;
        this.isLoading.next(this.activeRequests > 0);
    }
}
