// First, let's update the splitNumberWithComma function
export function splitNumberWithComma(value: number | string, forceDecimals: boolean = false): string {
    if (value === null || value === undefined || value === '') {
        return forceDecimals ? '0.00' : '0';
    }

    // Convert to string and remove existing formatting
    let strValue = value.toString().replace(/'/g, '');

    // Split into integer and decimal parts
    let [integerPart, decimalPart] = strValue.split('.');

    // Parse the integer part
    let parsedInteger = parseInt(integerPart, 10);

    if (isNaN(parsedInteger)) {
        return forceDecimals ? '0.00' : '0';
    }

    // Format the integer part with apostrophes
    let formattedInteger = parsedInteger.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    // Handle decimal part
    if (decimalPart !== undefined) {
        if (decimalPart.length > 2) {
            decimalPart = decimalPart.slice(0, 2);
        } else if (decimalPart.length === 1 && forceDecimals) {
            decimalPart += '0';
        }
        return `${formattedInteger}.${decimalPart}`;
    } else if (forceDecimals) {
        return `${formattedInteger}.00`;
    } else {
        return formattedInteger;
    }
}

// Now, let's update the SplitNumberWithCommaPipe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitNumberWithComma',
    pure: true,
    standalone: true,
})
export class SplitNumberWithCommaPipe implements PipeTransform {
    transform(value: number | string, withTwoSymbols = false): string {
        return splitNumberWithComma(value, withTwoSymbols);
    }
}
