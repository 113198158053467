export class RegisterRequest {
    ahvNumber: string;
    authCode: string;
    phoneNumber: string;
    enabled: boolean;
    login: string;
    password: string;
    emailHeader: string;
    emailSalutation: string;
    emailBody: string;
    emailBody2: string;
    emailSecurity: string;
    emailSupport: string;
    emailAfterLinkBody: string;
    emailRegards: string;
    lang: string;
    url: string;
}
