import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { LocaleService } from '../../../../core/services/locale.service';
import { CurrencyService } from '../../../../core/services/currency.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { VisualisationService } from '../../../../../app/core/services/visualisation.service';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '../../../../../app/core/services/user.service';
import { GlobalEventsService } from '../../../../../app/services/global/global-events-service';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { ColourThemePipe } from '../../../../shared/pipes/colour-theme.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, RouterLinkActive, TranslateModule, ColourThemePipe],
})
export class UserMenuComponent implements OnInit {
    showMenu = false;
    languageArray: Locale[];
    currentLocale: Locale;
    darkMode: boolean;
    currancyArray: Array<{ title: string; isCurrent: boolean }> = [
        {
            title: 'CHF',
            isCurrent: false,
        },
        {
            title: 'USD',
            isCurrent: true,
        },
        {
            title: 'EUR',
            isCurrent: false,
        },
    ];

    destroyed$ = new Subject<boolean>();
    hasAK = false;
    hasPK = false;

    private clickListener: () => void;

    constructor(
        private localeService: LocaleService,
        private visualisationService: VisualisationService,
        private currencyService: CurrencyService,
        private router: Router,
        private userService: UserService,
        private globalEventsService: GlobalEventsService,
        private renderer: Renderer2,
        private elRef: ElementRef
    ) {
        this.languageArray = this.localeService.localeList;
        visualisationService.$darkTheme.subscribe((data) => (this.darkMode = data));
    }

    ngOnInit() {
        this.localeService.$currentLocale.pipe(takeUntil(this.destroyed$)).subscribe((locale) => {
            this.currentLocale = locale;
        });
        this.userService.hasAK$.subscribe((hasAk) => {
            this.hasAK = hasAk;
        });
        this.globalEventsService.hasCabinet$.subscribe((hasCabinet) => {
            this.hasPK = hasCabinet;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeFromClickOutside();
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    toggleCurrencyValueVisibility() {
        this.currencyService.toggleCurrencyValuesVisibility();
    }
    toggleDarkMode(event) {
        this.visualisationService.toggleTheme();
        if (this.darkMode) {
            document.body.classList.remove('light');
        } else {
            document.body.classList.add('light');
        }
    }
    async openFagPage() {
        await this.router.navigate(['/cabinet/faq']);
    }
    toggleMenu(event) {
        this.showMenu = !this.showMenu;
        this.showMenu ? this.subscribeToClickOutside() : this.unsubscribeFromClickOutside();
        event.stopPropagation();
    }

    selectLang(locale: Locale) {
        this.localeService.changeLocale(locale);
        this.showMenu = false;
    }

    selectCurrancy(currancy: { title: string; isCurrent: boolean }) {
        this.currancyArray.forEach((item) => {
            item.isCurrent = item.title === currancy.title;
        });
    }

    subscribeToClickOutside(): void {
        this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
            const clickedInside = this.elRef.nativeElement.contains(event.target);
            if (!clickedInside) {
                this.clickOutside();
            }
        });
    }

    unsubscribeFromClickOutside(): void {
        if (this.clickListener) {
            this.clickListener();
            this.clickListener = null;
        }
    }

    clickOutside(): void {
        this.showMenu = false;
        this.unsubscribeFromClickOutside();
    }
}
