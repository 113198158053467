<div class="login-form-BackButton">
  <!--Registration Form  -->
  <div class="Login-Title-Box">
    <div class="swarmLogo" [ngClass]="{ light: 1 }"></div>
  </div>

  @if (!registrationSuccess) {
    <div class="login-form">
      <div class="languageSelection">
        <div class="selectionBox">
          @for (item of allLanguages; track item; let i = $index) {
            <p
              class="LanguageSelection"
              [ngClass]="{ light: 1 }"
              [class.reset-password__modal-value--active]="item === currentLocale"
              (click)="selectLanguage(item)"
              >
              {{ item }}
            </p>
          }
        </div>
      </div>
      <div class="loginTextReg" [ngClass]="{ light: 1 }">
        {{ 'auth.register' | translate }}
      </div>
      <form [formGroup]="registrationFormGroup" (ngSubmit)="onSubmitRegistration()" #registrationForm>
        <div class="form-group form-group-tenant">
          <lib-tenant-field
            class="app-input"
            [tenantControl]="tenantRegistrationControl"
            (emitSelectedTenantData)="receiveSelectedTenant($event)"
          ></lib-tenant-field>
        </div>
        <!--PersonId-->
        <mat-form-field
          appearance="outline"
          [matTooltip]="'auth.PersonIdHelpText' | translate"
          matTooltipPosition="after"
          >
          <mat-label>{{ 'auth.personId' | translate }}</mat-label>
          <input matInput formControlName="personId" required />
          @if (personId?.hasError('required')) {
            <mat-error>{{
              'auth.errors.fieldRequired' | translate
            }}</mat-error>
          }
        </mat-form-field>
        <!--Auth Code-->
        <mat-form-field
          appearance="outline"
          [matTooltip]="'auth.AuthcodeHelpText' | translate"
          matTooltipPosition="after"
          >
          <mat-label>{{ 'auth.authCode' | translate }}</mat-label>
          <input matInput formControlName="authCode" required />
          @if (authCode?.hasError('required')) {
            <mat-error>{{
              'auth.errors.register.authCode.required' | translate
            }}</mat-error>
          }
        </mat-form-field>
        <!--Natel Nr Block-->
        <mat-form-field
          appearance="outline"
          matToolTip="auth.MobilenumberHelpText"
          [matTooltip]="'auth.MobilenumberHelpText' | translate"
          matTooltipPosition="after"
          >
          <mat-label> {{ 'auth.phoneNumber' | translate }}</mat-label>
          <input matInput formControlName="number" required />
          @if (number?.hasError('required')) {
            <mat-error>{{
              'auth.errors.fieldRequired' | translate
            }}</mat-error>
          }
        </mat-form-field>
        <!--Email-->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'auth.email' | translate }}</mat-label>
          <input matInput formControlName="email" required />
          @if (email?.hasError('required')) {
            <mat-error>{{
              'auth.errors.email.required' | translate
            }}</mat-error>
          }
          @if (email?.hasError('email')) {
            <mat-error>{{ 'auth.errors.email.incorrect' | translate }}</mat-error>
          }
        </mat-form-field>
        <!--Password-->
        <mat-form-field
          appearance="outline"
          class="password-input"
          [matTooltip]="'auth.passwordHelpText' | translate"
          matTooltipPosition="after"
          >
          <mat-label>{{ 'auth.password' | translate }}</mat-label>
          <input matInput [type]="type" formControlName="password" required />
          @if (type === 'password') {
            <mat-icon (click)="toggleVisibility('password')"
              >visibility_off</mat-icon
              >
            } @else {
              <mat-icon (click)="toggleVisibility('text')">visibility</mat-icon>
            }
            @if (password?.hasError('required')) {
              <mat-error>{{
                'auth.errors.password.required' | translate
              }}</mat-error>
            }
            @if (password?.hasError('minlength')) {
              <mat-error>{{
                'auth.errors.password.required' | translate
              }}</mat-error>
            }
          </mat-form-field>
          <!--Alert text if email already exists-->
          @if (noTenantSelected === true) {
            <div>
              <p class="error-text-user-already-exists">
                {{ 'auth.errors.register.noTenantSelected' | translate }}
              </p>
            </div>
          }
          @if (doesUserAlreadyExist === true) {
            <div>
              <p class="error-text-user-already-exists">
                {{ 'auth.errors.register.userAlreadyExists' | translate }}
              </p>
            </div>
          }
          @if (authCodeDosntMatch === true) {
            <div>
              <p class="error-text-user-already-exists">
                {{ 'auth.errors.fieldRequired' | translate }}
              </p>
            </div>
          }
          @if (ssn13notFound === true) {
            <div>
              <p class="error-text-user-already-exists">
                {{ 'auth.errors.register.ssn13' | translate }}
              </p>
            </div>
          }
          @if (ahvAlreadyActivated === true) {
            <div>
              <p class="error-text-user-already-exists">
                {{ 'auth.errors.register.ahvAlreadyActivated' | translate }}
              </p>
            </div>
          }
          <div class="form-group" style="margin-top: 20px">
            <!-- Button to return to login page -->
            @if (!registrationSuccess) {
              <a routerLink="/auth" class="backLink">
                <div class="backBtn">
                  <span style="top: 50%">{{ 'cabinet.profile.buttons.back' | translate }}</span>
                </div>
              </a>
            }
            <button
              class="submit-button"
              [ngClass]="{ light: 1 }"
              type="submit"
              [disabled]="registrationFormGroup.invalid"
              >
              <span>{{ 'auth.register' | translate }}</span>
            </button>
          </div>
        </form>
        <div class="version">
          Build: {{ version?.semverString }}
          <!--({{ version.date}})-->
        </div>
      </div>
    }
    @if (registrationSuccess === true) {
      <div class="successDisplay">
        <p class="instructionText" [ngClass]="{ light: 1 }">
          {{ 'auth.registerSuccessText' | translate }}
        </p>
        <div class="form-group">
          <a routerLink="/auth"
            ><button class="submit-button" [ngClass]="{ light: 1 }" type="submit">
            <span>{{ 'auth.login' | translate }}</span>
          </button></a
          >
        </div>
      </div>
    }
  </div>
