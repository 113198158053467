import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './guards/auth.guard';
import { DeactivateGuard } from './guards/deactivate.guard';
import { CashWithdrawalSimulationService } from './services/cash-withdrawal-simulation.service';
import { CurrencyService } from './services/currency.service';
import { LocaleService } from './services/locale.service';
import { MyPensionService } from './services/my-pension.service';
import { TransactionsService } from './services/transactions.service';
import { UserService } from './services/user.service';
import { VisualisationService } from './services/visualisation.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthenticationService } from 'clan-auth';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
    providers: [
        CookieService,
        AuthenticationService,
        CurrencyService,
        LocaleService,
        UserService,
        TransactionsService,
        AuthGuard,
        DeactivateGuard,
        MyPensionService,
        CashWithdrawalSimulationService,
        VisualisationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: Window,
            useValue: window,
        },
    ],
})
export class CoreModule {}
