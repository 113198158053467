export abstract class AuthEvent {
    date: Date

    protected constructor(date?: Date) {
        if(date){
            this.date = date
        }else{
            this.date = new Date()
        }
    }
}

export class LoginEvent extends AuthEvent{
    constructor(date?: Date) {
        super(date);
    }
}

export class LogoutEvent extends AuthEvent{

    constructor(date?: Date) {
        super(date);
    }
}

