import { LocaleService } from './../../../../../../../apps/swarm/src/app/core/services/locale.service';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantService } from '../../../tenant.service';
import { Subject, Subscription, combineLatest, concatMap, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

import { DirectoryService } from '../../../directory.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Directory } from '../../../model/Directory';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';

import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'lib-tenant-field',
    templateUrl: './tenant-field.component.html',
    styleUrls: ['./tenant-field.component.scss'],
    standalone: true,
    imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
    MatError,
    TranslateModule
],
})
export class TenantFieldComponent implements OnInit, OnDestroy {
    @ViewChild('tenantElement') tenantElement!: ElementRef;
    @Output() emitSelectedTenantData = new EventEmitter<Directory | undefined>();
    @Output() emitParamTenantDirectory = new EventEmitter<any>();
    @Output() emitTenantFieldStatus = new EventEmitter<string>();
    @Output() emitTenantEmpty = new EventEmitter<boolean>(false);
    @Input() tenantControl = new FormControl();
    directoriesList: Directory[] = [];
    currentLocale: Locale = 'de';
    showTenantList = false;
    selectedTenant: string | undefined;
    value = '';
    active = false;
    tenant = {
        active: false,
        type: 'text',
    };
    tenantValue!: string;
    selectedFromDropdown = false;
    private destroy$ = new Subject<void>();
    directory: Directory;
    changeLanguage!: Subscription;
    required = false;
    cookieTokenName = 'swarm-auth-token';
    cookieRefreshTokenName = 'swarm-refresh-token';
    adClientId!: string;
    adRedirectUri!: string;
    adResponseType!: string;
    adScope!: string;
    keycloakUrl!: string;
    tenantId!: string;
    tenantValues!: any;
    paramTenant!: any;

    constructor(
        private tenantService: TenantService,
        private localeService: LocaleService,
        private directoryService: DirectoryService,
        private route: ActivatedRoute,
        private cookieService: CookieService
    ) {}
    ngOnInit(): void {
        combineLatest([
            this.localeService.$currentLocale,
            this.tenantControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()),
        ])
            .pipe(takeUntil(this.destroy$))
            .forEach(([locale, value]) => {
                if (!value) {
                    this.tenantService.updateUrlParams('');
                    this.emitTenantEmpty.emit(true);
                }
                this.currentLocale = locale as Locale;
                this.updateDirectoriesForLocale(this.currentLocale, value);
                this.changeLanguage = this.tenantService.languageSelected$.subscribe((data) => {
                    if (this.directory && this.directory[`name_${locale}`] === value) {
                        this.selectedFromDropdown = true;
                        this.tenantControl.setValue(this.directory[`name_${data}`]);
                    } else {
                        this.tenantService.setDirectory(null);
                        this.selectedFromDropdown = false;
                    }
                });
            });
        this.tenantControl.statusChanges.subscribe((status) => {
            if (status === 'INVALID' && this.tenantControl.touched) {
                this.emitTenantFieldStatus.emit();

                this.required = true;
            } else if (status === 'VALID') {
                this.required = false;
            }
        });

        this.currentLocale = this.cookieService.get('lang') as Locale;
        this.directoryService.getMatchingTenant('', this.currentLocale).subscribe((res) => {
            const currentUrl = location.href;

            const tenants = res.filter((tenant) => {
                const configObject = tenant['tenant_config'] ? JSON.parse(tenant['tenant_config']).config : {};

                const dynamicKey = Object.keys(configObject)[0];
                return configObject[dynamicKey].branding.default_tenant_login_url.includes(currentUrl);
            });

            tenants[0] ? this.selectTenantTitle(tenants[0]) : this.checkParamsForTenant(this.currentLocale);
        });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    checkParamsForTenant(lang: string) {
        this.route.queryParams
            .pipe(
                concatMap((params) => {
                    this.tenantValue = params['tenant'];
                    return this.tenantService.getTenantValue();
                })
            )
            .subscribe((param) => {
                if (param) {
                    this.directoryService.getMatchingTenant(param, lang).subscribe({
                        next: (data) => {
                            const directory = data.filter((directory) => directory.tenantId === param)[0];

                            this.tenantService.setDirectory(directory);
                            this.emitSelectedTenantData.emit(directory);
                            this.tenantControl.setValue(directory[`name_${lang}`]);
                            if (directory?.apiUrl) {
                                sessionStorage.setItem('api-url', directory.apiUrl);
                            }
                        },
                        error: (err) => console.error(err),
                    });
                }
            });
    }

    getActiveIndex(): number {
        return this.tenantService.getActiveIndex();
    }

    selectTenantTitle(directory: Directory) {
        this.selectedFromDropdown = true;
        this.tenantService.selectTenantTitle(directory);
        this.tenantControl.setValue(this.getLocalizedTenantName(directory, this.currentLocale));
        this.selectedTenant = directory.tenantId;

        this.emitSelectedTenantData.emit(directory);

        this.tenant.active = true;
        this.showTenantList = false;
        this.directory = directory;

        this.tenantControl.setValue(this.directory[`name_${this.currentLocale}`]);
        if (this.directory.tenantId) this.tenantService.updateUrlParams(this.directory.tenantId);
    }
    onChange(event: any) {
        const inputElement = event.target as HTMLInputElement;
        const newValue = inputElement.value;
        this.directory = this.tenantService.getUserData();
        if (this.directory && this.directory[`name_${this.currentLocale}`] == newValue) {
            this.tenantService.setDirectory(this.directory);
        }
    }
    getLocalizedTenantName(directory: any, locale: string): string {
        switch (locale) {
            case 'fr':
                return directory.name_fr;
            case 'en':
                return directory.name_en;
            case 'it':
                return directory.name_it;
            default:
                return directory.name_de;
        }
    }
    updateDirectoriesForLocale(locale: Locale, value: string): void {
        this.directoryService.getMatchingTenant(value, locale).subscribe({
            next: (data) => {
                if (data.length > 0) {
                    this.directoriesList = data;
                    this.showTenantList = true;
                }
                return (this.directoriesList = data);
            },
            error: (err) => console.error(err),
        });
    }

    closeTenantList() {
        this.showTenantList = false;
        if (this.tenantElement.nativeElement.value == '' || this.tenantElement.nativeElement.value == undefined) {
            return;
        }
        this.tenant.active = true;
    }
}
