import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, Subscription, map } from 'rxjs';
import { TenantService } from '../../tenant.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Auth } from '../../model/AuthModel';
import { AuthenticationService } from '../../authentication.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Config } from 'ng-otp-input/lib/models/config';
import { HTTPStatus } from '../../http-status.service';
import { SSOService } from '../../sso.service';
import { LoginEvent } from '../../model/AuthEvent';
import { AuthObserver } from '../../auth-observer';
import { SnackbarService } from '../../snackbar.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Directory } from '../../model/Directory';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { environment } from 'apps/swarm/src/environments/environment';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { TenantFieldComponent } from '../../shared/components/tenant-field/tenant-field.component';
import { NgClass } from '@angular/common';
import { HeaderComponent } from '../../shared/components/header/header.component';
@Component({
    selector: 'lib-auth-login-form',
    templateUrl: './auth-login-form.component.html',
    styleUrls: ['./auth-login-form.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        NgClass,
        FormsModule,
        ReactiveFormsModule,
        TenantFieldComponent,
        MatFormField,
        MatLabel,
        MatInput,
        MatError,
        MatIcon,
        MatSuffix,
        RouterLink,
        NgOtpInputModule,
        TranslateModule,
    ],
})
export class AuthLoginFormComponent implements OnInit {
    @Input() showAzureId: boolean;
    @Input() environment: any;
    loginForm: FormGroup;
    directoriesList = [];
    currentLocale: Locale;
    requestPending = false;
    changeLanguage: Subscription;
    directory: Directory;
    allLanguages: Locale[] = environment.localeData.locales;
    loginSuccess = false;
    authCurrentStep: 'login' | 'registration' | 'registrationDone' | 'otp' = 'login';
    otpInputConfig: Config = {
        allowNumbersOnly: true,
        length: 6,
        containerClass: 'auth-second-step-container',
        inputClass: 'auth-second-step-input',
    };
    type = 'password';
    private authId: string;
    showUnauthorizedSite = false;
    loginWithAzure = false;
    submitted = false;
    loginErrors = false;
    noTenantSelected = false;
    formValid = true;
    version: any;
    loginBtnVisible = false;
    cookieTokenName = 'swarm-auth-token';
    cookieRefreshTokenName = 'swarm-refresh-token';
    tenantConfig: any;
    tenantValues: any;
    azureAdLogin = false;
    forgotPassword = false;
    registrationForm = false;
    allowedRoles: string[];
    logoUrl: string;
    defaultTenantLoginUrl: string;
    redirectToCookieName = 'redirectTo';
    transactionIdCookieName = 'transactionId';

    private destroy$ = new Subject<void>();
    private tenantParam: string;
    constructor(
        private fb: FormBuilder,
        private _localeService: LocaleService,
        private _tenantService: TenantService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _authenticationService: AuthenticationService,
        private _cookieService: CookieService,
        private _statusService: HTTPStatus,
        private _ssoService: SSOService,
        private _http: HttpClient,
        private _snackbarService: SnackbarService,
        private _translateService: TranslateService,
        private observer: AuthObserver
    ) {}

    ngOnInit() {
        this._activatedRoute?.fragment?.subscribe((fragment) => {
            const params = new URLSearchParams(fragment || '');
            const codeValue = params.get('code');
            if (codeValue) {
                this.exchangeCodeForToken(codeValue);
            }
        });

        this._localeService.$currentLocale.subscribe((locale) => {
            this.currentLocale = locale;
        });
        this.loginForm = this.fb.group({
            tenant: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        this.currentLocale = this._cookieService.get('lang') as Locale;
        this.selectLanguage(this.currentLocale);
        this._statusService.getLoader().subscribe((isLoading) => {
            if (!isLoading) {
                this.requestPending = false;
                this.authCurrentStep = 'login';
            }
        });
    }

    get tenant() {
        return this.loginForm.get('tenant') as FormControl;
    }
    get email() {
        return this.loginForm.get('email');
    }
    get password() {
        return this.loginForm.get('password');
    }
    receiveSelectedTenant(tenantData: any) {
        if (tenantData) {
            this.loginBtnVisible = true;
        }

        this.tenantValues = {
            keycloakUrl: tenantData.authorizeUrl,
            adClientId: tenantData.ad_clientid,
            adRedirectUri: tenantData.ad_redirecturi,
            adResponseType: tenantData.ad_responsetype,
            adScope: tenantData.ad_scope,
            tenantId: tenantData.tenantId,
            apiUrl: tenantData.apiUrl,
        };
        this.tenantConfig = JSON.parse(tenantData.tenant_config);
        this.azureAdLogin = this.tenantConfig?.config[this.tenantValues?.tenantId]?.AzureAdLogin?.active;
        this.forgotPassword = this.tenantConfig?.config[this.tenantValues?.tenantId]?.ForgotPassword?.active;
        this.registrationForm = this.tenantConfig?.config[this.tenantValues?.tenantId]?.RegistrationForm?.active;
        this.allowedRoles = this.tenantConfig?.config[this.tenantValues?.tenantId]?.AzureAdLogin?.allowedRoles;
        this.loginBtnVisible = this.tenantConfig?.config[this.tenantValues?.tenantId]?.StandardLogin?.active;
        this.logoUrl = this.tenantConfig?.config[this.tenantValues?.tenantId]?.branding?.logoUrl;
        this.defaultTenantLoginUrl =
            this.tenantConfig?.config[this.tenantValues?.tenantId]?.branding?.default_tenant_login_url.split('auth')[1];
        const branding = this.tenantConfig?.config[this.tenantValues?.tenantId]?.branding;
        this.fillBodyElement(branding);

        sessionStorage.setItem('azure-info', JSON.stringify(this.tenantValues));
        sessionStorage.setItem('tenant', this.tenantValues.tenantId);
        sessionStorage.setItem('api-url', this.tenantValues.apiUrl);
        sessionStorage.setItem('allowed-roles', this.allowedRoles.toString());
        sessionStorage.setItem('default_tenant_login_url', this.defaultTenantLoginUrl);
    }
    receiveIsTenantEmpty(event: any) {
        if (event) {
            this.loginBtnVisible = false;
        }
    }

    removeQueryParams() {
        this._router.navigate([], {
            queryParams: {
                status: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    selectLanguage(locale: Locale) {
        this.currentLocale = locale;
        this._localeService.changeLocale(locale);
        this._tenantService.selectLanguage(locale);
        this._tenantService.languageChanged(locale);
    }
    loginAzure() {
        this.loginWithAzure = true;
        this.loginForm.controls['email'].clearValidators();
        this.loginForm.controls['email'].updateValueAndValidity();

        this.loginForm.controls['password'].clearValidators();
        this.loginForm.controls['password'].updateValueAndValidity();
        this.checkForRedirectLink();
        this._ssoService.initializeKeycloak(this.tenantValues);
    }

    checkForRedirectLink() {
        const redirectTo = this._activatedRoute.snapshot.queryParamMap.get('redirectTo');
        const transactionId = this._activatedRoute.snapshot.queryParamMap.get('transactionId');
        if (redirectTo && transactionId) {
            localStorage.setItem(this.redirectToCookieName, redirectTo);
            localStorage.setItem(this.transactionIdCookieName, transactionId);
        }
    }

    exchangeCodeForToken(authorizationCode: any) {
        const azureInfo = sessionStorage.getItem('azure-info');
        const tenantValues = azureInfo ? JSON.parse(azureInfo) : null;
        const allowedRoles = sessionStorage.getItem('allowed-roles')?.split(',') || [];

        const tokenEndpoint = `${tenantValues.keycloakUrl}/realms/${tenantValues.tenantId}/protocol/openid-connect/token`;

        const params = new HttpParams()
            .append('grant_type', 'authorization_code')
            .append('client_id', tenantValues?.adClientId)
            .append('code', authorizationCode)
            .append('redirect_uri', tenantValues?.adRedirectUri);
        return this._http
            .post(tokenEndpoint, params.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .subscribe({
                next: (response: any) => {
                    const tokenPayload = SSOService.parseJwt(response['access_token']);

                    if (allowedRoles) {
                        for (const role of tokenPayload.realm_access.roles) {
                            if (allowedRoles.includes(role)) {
                                this.loginWithAzure = true;
                            }
                        }
                    }

                    if (this.loginWithAzure) {
                        sessionStorage.setItem(this.cookieTokenName, response['access_token']);
                        sessionStorage.setItem(this.cookieRefreshTokenName, response['refresh_token']);
                        this.observer.addNewEvent(new LoginEvent());
                    } else {
                        this._snackbarService.handleOpenSnackbar(
                            this._translateService.instant('auth.errors.noAllowedRoles'),
                            this._translateService.instant('cabinet.navigation.close'),
                            'error'
                        );
                    }
                },
                error: (err) => console.error(err.message),
                complete: () => {
                    sessionStorage.removeItem('azure-info');
                    sessionStorage.removeItem('allowed-roles');
                },
            });
    }
    loginSubmit() {
        if (!this.loginWithAzure) {
            this.submitted = true;
            this.loginErrors = false;
            this.noTenantSelected = false;
            if (this.tenantValues.tenantId == undefined) {
                this.noTenantSelected = true;
                return;
            }
            if (this.loginForm.errors) {
                this.formValid = false;
                return;
            }
            this.formValid = true;
            this.requestPending = true;

            this._authenticationService
                .loginFirstStep<AuthFirstStepRes>(
                    {
                        login: this.loginForm.controls['email'].value,
                        password: this.loginForm.controls['password'].value,
                    },
                    this.tenantValues.tenantId
                )
                .pipe(map((res) => res.authId))
                .subscribe({
                    next: (res) => {
                        this.authId = res;
                        this.authCurrentStep = 'otp';
                        this.requestPending = false;
                        this.showUnauthorizedSite = false;
                    },
                    error: (error) => {
                        this.loginErrors = true;
                        this.requestPending = false;
                        if (error.status == 401) {
                            this.showUnauthorizedSite = true;
                        }
                    },
                });
        }
    }

    submitOtp(otpCode: string) {
        this.requestPending = true;

        this.loginSuccess = true;
        this._authenticationService.loginSecondStep<Auth>({ authId: this.authId, otp: otpCode }).subscribe({
            next: async (res) => {
                if (res == null) {
                    this.requestPending = false;

                    this.loginSuccess = false;
                }
                if (res != null) {
                    const directory = this._tenantService.getUserData();
                    sessionStorage.setItem('api-url', directory.apiUrl);
                    this._authenticationService.logIn(
                        {
                            token: res.access_token,
                            refreshToken: res.refresh_token,
                        },
                        this.tenantValues.tenantId
                    );
                }
            },
            error: (error) => {
                this.loginSuccess = false;
                if (error instanceof HttpErrorResponse) {
                    console.error(error.message);
                    alert(error.message);
                }
            },
        });
    }

    onOtpInputChange(value: string) {
        if (value.trim() && value.length === this.otpInputConfig.length) {
            this.submitOtp(value);
        }
    }
    registrationClicked() {
        this._router.navigate(['auth/registration'], {
            queryParams: { tenant: this.tenantValues.tenantId },
        });
    }
    forgotPasswordClicked() {
        this._router.navigate(['auth/resetPassword'], {
            queryParams: { tenant: this.tenantValues.tenantId },
        });
    }

    toggleVisibility(type: string) {
        this.type = type;
        this.type = this.type === 'password' ? 'text' : 'password';
    }

    fillBodyElement(branding: any) {
        const themeWrapper = document.querySelector('body');
        if (themeWrapper) {
            if (branding.primary_color) {
                themeWrapper.style.setProperty('--primary', branding.primary_color);
            }

            if (branding.secondary_color) {
                themeWrapper.style.setProperty('--secondary', branding.secondary_color);
            }

            if (branding.mouse_over) {
                themeWrapper.style.setProperty('--hover', branding.mouse_over);
            }

            if (branding.primary_font) {
                themeWrapper.style.setProperty('--primary-font', branding.primary_font);
            }

            if (branding.header_text_color) {
                themeWrapper.style.setProperty('--header-text-color', branding.header_text_color);
            }
        }
    }
}

type AuthFirstStepRes = {
    authId: string;
};
