import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthRegistrationFormComponent } from './components/auth-registration-form/auth-registration-form.component';
import { AuthLibRoutingModule } from './auth-routing.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PwResetFormComponent } from './components/pw-reset-form/pw-reset-form.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { SharedModule } from './shared/shared.module';
import { KeycloakService } from 'keycloak-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TenantService } from './tenant.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { ErrorInterceptor } from './helpers/error-interceptor';
// import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
// import { initializeKeycloak } from './utils/app.init';

export function HttpLoadFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoadFactory,
                deps: [HttpClient],
            },
            useDefaultLang: false,
        }),
        MatIconModule,
        NgOtpInputModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        ReactiveFormsModule,
        HttpClientModule,
        AuthLibRoutingModule,
        MatAutocompleteModule,
        SharedModule,
        MatTooltipModule,
        AuthRegistrationFormComponent,
        ResetPasswordComponent,
        PwResetFormComponent,
        EmailVerificationComponent,
    ],
    providers: [
        TenantService,
        { provide: LocaleService, useClass: LocaleService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: KeycloakService,
            useValue: new KeycloakService(),
        },
    ],
    exports: [],
})
export class LibraryAuthModule {}
