import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication.service';
import { LocaleService } from 'apps/swarm/src/app/core/services/locale.service';
import { Locale } from 'libs/shared/src/lib/models/types/locale';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lib-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    RouterLink,
    TranslateModule
],
})
export class EmailVerificationComponent implements OnInit {
    resetSuccess = false;
    isTokenValid = false;
    // 0 = invalid token
    // 1 = valid token
    tenant = '';
    token = '';
    language = '';

    currentLocale: Locale;
    currentLanguage: Locale;

    constructor(
        private localeService: LocaleService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.localeService.$currentLocale.subscribe((locale) => {
            this.currentLanguage = locale;
        });
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['token'] && params['lang'] && params['tenant']) {
                this.token = params['token'];
                this.language = params['lang'];
                this.tenant = params['tenant'];
            } else {
                // TODO: some error thingy
            }
        });

        // this.selectLanguage(this.currentLanguage);
        this.authenticationService
            .checkIfResetPasswordTokenValid<tokenData>({
                token: this.token,
            })
            .pipe(map((res) => res))
            .subscribe((res) => {
                if (res.toString() == '0') {
                    this.isTokenValid = false;
                }
                if (res.toString() == '1') {
                    this.isTokenValid = true;
                }
            });
    }

    activateEmail() {
        this.authenticationService
            .activateEmail<tokenData>(
                {
                    token: this.token,
                },
                this.tenant
            )
            .pipe(map((res) => res))
            .subscribe((res) => {
                if (res.toString() == '0') {
                    this.isTokenValid = false;
                }
                if (res.toString() == '1') {
                    this.isTokenValid = true;
                }
            });
    }

    // selectLanguage( locale: Locale ) {
    //     this.localeService.changeLocale(locale);
    // }
}

type tokenData = {
    token: string;
};
