import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

type TSnackbarType = 'success' | 'error' | 'default';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private _snackBar: MatSnackBar) {}

    handleOpenSnackbar(
        message: string,
        action: string,
        type: TSnackbarType,
        config?: MatSnackBarConfig<unknown>
    ) {
        let typeClass: string;

        switch (type) {
            case 'success':
                typeClass = 'snackbar--success';
                break;
            case 'error':
                typeClass = 'snackbar--error';
                break;
            default:
                typeClass = 'snackbar--default';
        }

        const defaultConfig = {
            duration: 5000,
            verticalPosition: 'top' as MatSnackBarVerticalPosition,
            horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
            panelClass: [typeClass],
        };

        this._snackBar.open(message, action, { ...defaultConfig, ...config });
    }
}
