import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LibraryAuthModule } from 'clan-auth';
import 'hammerjs';
import { LoadingInterceptor } from 'libs/shared/src/lib/interceptors/loading.interceptor';
import { LoadingService } from 'libs/shared/src/lib/services/loading.service';
import { NgxMaskConfig, provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app/app-routing.module';
import { CoreModule } from './app/core/core.module';
import { ErrorInterceptor } from './app/core/interceptors/error.interceptor';
import { MaterialModule } from './app/material/material.module';
import { MissingLocaleService } from './app/missing-locale.service';
import { AppComponent } from './app/modules/app/components/app/app.component';
import { CMModule } from './app/modules/content-management/cm.module';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoadFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

const maskConfigFunction: () => Partial<NgxMaskConfig> = () => {
    return {
        thousandSeparator: "'",
    };
};

if (environment.production) {
    enableProdMode();
}

const bootstrap = () =>
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(
                FormsModule,
                BrowserModule,
                ReactiveFormsModule,
                AppRoutingModule,
                MatSelectModule,
                LibraryAuthModule,
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useFactory: HttpLoadFactory,
                        deps: [HttpClient],
                    },
                    missingTranslationHandler: {
                        provide: MissingTranslationHandler,
                        useClass: MissingLocaleService,
                    },
                    useDefaultLang: false,
                }),
                CoreModule,
                CommonModule,
                SharedModule,
                MatIconModule,
                MatDatepickerModule,
                MatMomentDateModule,
                CMModule,
                MaterialModule
            ),
            {
                provide: HTTP_INTERCEPTORS,
                useClass: ErrorInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: LoadingInterceptor,
                multi: true,
            },
            LoadingService,
            provideNgxMask(maskConfigFunction),
            provideAnimations(),
            provideHttpClient(withInterceptorsFromDi()),
        ],
    });

bootstrap().catch((err) => console.error(err));
