<div>
  <div class="login-title-box">
    @if (showClanLogo) {
      <div class="img-box">
        <img
          class="login-page-image"
          src="../../../assets/img/clanLogo/Logo.png"
          alt="logo"
          />
        </div>
      }

      @if (!showClanLogo) {
        <div class="img-box">
          <img
            class="login-page-image"
            [src]="logoUrl ?? envLogoPrefix + envLogo"
            alt="logo"
            />
          </div>
        }
      </div>
    </div>
